
import Vue from "vue";
import { get, set } from "lodash-es";

export default Vue.extend({
  name: "URLField",
  props: {
    entity: Object,
    options: Object,
  },
  inject: ["isNewEntity"],
  computed: {
    localValue: {
      set(val: string) {
        const entity = { ...this.entity };
        set(
          entity,
          this.options.title,
          encodeURIComponent(val).replace(/[!'()*]/g, (c) => {
            return "%" + c.charCodeAt(0).toString(16);
          })
        );
        this.$emit("commit", entity);
      },
      get(): Object {
        return decodeURIComponent(get(this.entity, this.options.title) || "");
      },
    },
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      );
    },
  },
  data() {
    return {};
  },
});
