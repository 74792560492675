import InputField from "./fields/InputField.vue";
import JSONField from "./fields/JSONField.vue";
import ArrayField from "./fields/ArrayField.vue";
import URLField from "./fields/URLField.vue";
import NumberField from "./fields/NumberField.vue";
import ToggleField from "./fields/ToggleField.vue";
import EnumField from "./fields/EnumField.vue";
import TextField from "./fields/TextField.vue";
import ScopesField from "./fields/ScopesField.vue";
import UsersField from "./fields/UsersField.vue";
import TypeField from "./fields/TypeField.vue";
import GroupsField from "./fields/GroupsField.vue";
import ImageField from "./fields/ImageField.vue";
import IconField from "./fields/IconField.vue";
import CoordinatesField from "./fields/CoordinatesField.vue";
import ReferenceField from "./fields/ReferenceField.vue";
import PermissionsSubjectsField from "./fields/PermissionsSubjectsField.vue";
import AddressField from "./fields/AddressField.vue";
import ComplexObjectStructure from "./fields/ComplexObjectStructure.vue";
import BorderField from "./fields/BorderField.vue";
import FeaturesField from "./fields/FeaturesField.vue";
import AdminDialogButton from "./fields/AdminDialogButton.vue";
import DeviceLocationField from "./fields/DeviceLocationField.vue";
import CameraDirectionField from "./fields/CameraDirectionField.vue";
import LabelLocationField from "./fields/LabelLocationField.vue";
import ScheduleField from "./fields/ScheduleField.vue";
import SetDefault from "./fields/SetDefault.vue";
import MultiReferenceField from "./fields/MultiReferenceField.vue";
import DurationField from "./fields/DurationField.vue";
import WidgetMountField from "./fields/WidgetMountField.vue";
import ComparedField from "./fields/ComparedField.vue";

export const arrayMap = {
  string: InputField,
  enum: EnumField,
  number: NumberField,
};

export default {
  string: InputField,
  text: TextField,
  scopes: ScopesField,
  object: JSONField,
  url: URLField,
  number: NumberField,
  array: ArrayField,
  boolean: ToggleField,
  enum: EnumField,
  users: UsersField,
  entityType: TypeField,
  groups: GroupsField,
  image: ImageField,
  icon: IconField,
  coordinates: CoordinatesField,
  groupOrUser: PermissionsSubjectsField,
  reference: ReferenceField,
  address: AddressField,
  complexObjectStructure: ComplexObjectStructure,
  border: BorderField,
  features: FeaturesField,
  direction: CameraDirectionField,
  adminDialog: AdminDialogButton,
  coordinatesInComplexObject: DeviceLocationField,
  labelLocation: LabelLocationField,
  schedule: ScheduleField,
  setDefault: SetDefault,
  multiReference: MultiReferenceField,
  duration: DurationField,
  mount: WidgetMountField,
  compare: ComparedField
};
