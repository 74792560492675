
import Vue from "vue";
import "maptalks.plotsymbol";
//@ts-ignore
import { Autoadsorb } from "maptalks.autoadsorb";
//@ts-ignore
import { Marker, DrawTool } from "maptalks";
import { getWidgetMap } from "./mapStore";
import { mapStateTyped } from "@/store";
import { findParentLayer, pointsToAnglesSector, uuid } from "@/utils";
export default Vue.extend({
  name: "TDrawTool",
  props: {
    mode: String,
    options: Object,
  },
  data() {
    return {
      autoAdsorb: {} as any,
      startMarker: { remove() {} } as any,
      parentMap: {} as any,
      drawTool: {} as any,
    };
  },
  computed: {
    ...mapStateTyped(["widgetUUID"]),
  },
  watch: {
    options: {
      handler(val) {
        this.drawTool.setOptions(val);
      },
      deep: true,
    },
    mode(val) {
      this.drawTool.setMode(val);
    },
  },
  mounted() {
    this.parentMap = getWidgetMap(this.widgetUUID);
    const layer = findParentLayer(this);
    this.drawTool = new DrawTool({ mode: this.mode, ...this.options }).addTo(
      this.parentMap
    );
    this.autoAdsorb = new Autoadsorb({
      mode: "vertux",
      distance: 20,
    });
    // library bug
    this.autoAdsorb.remove = () => {};
    this.autoAdsorb.setLayer(layer);
    if (this.mode === "Polygon") {
      this.drawTool.once("drawstart", (event: any) => {
        const { coordinate } = event;
        this.startMarker = new Marker(coordinate, {
          symbol: {
            markerType: "ellipse",
            markerFill: "white",
            markerLineColor: "black",
            markerLineWidth: 2,
            markerWidth: 10,
            markerHeight: 10,
          },
        }).addTo(layer);
        this.startMarker.once("click touch", () => {
          this.drawTool.endDraw();
        });
      });
      // there is problem with detecting last click
      this.drawTool.on("drawvertex", ({ drawTool }: any) => {
        const currentGometry = drawTool.getCurrentGeometry();
        if (
          currentGometry
            .getFirstCoordinate()
            .equals(currentGometry.getLastCoordinate())
        ) {
          this.drawTool.endDraw();
        }
      });
    }
    this.parentMap.once("contextmenu", () => {
      this.drawTool.endDraw();
    });

    this.drawTool.once("drawend", ({ geometry }: any) => {
      let result = {} as any;
      const coordinates = geometry.getCoordinates();

      switch (true) {
        case this.mode === "Sector" && coordinates.length === 3:
          result = pointsToAnglesSector(coordinates);
          break;
        case this.mode === "Point":
          result = geometry.toGeoJSON();
          result["properties"] = { label: this.$t("defaultText") };
          break;
        default:
          result = geometry.toGeoJSON();
          break;
      }
      result.id = uuid();
      this.$emit("endDraw", result);
    });
  },
  beforeDestroy() {
    this.parentMap.removeLayer("_maptalks__internal_layer__Autoadsorb");
    this.autoAdsorb.remove();
    this.startMarker.remove();
  },
});
