
import Vue from "vue";
import { mapStateTyped } from "@/store";
import { get, set } from "lodash-es";
import MultiSelect from "primevue/multiselect";

export default Vue.extend({
  name: "ScopesField",
  components: {
    MultiSelect,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      scopes: [] as any,
    };
  },
  inject: ["isNewEntity"],
  computed: {
    ...mapStateTyped(["api"]),
    localValue: {
      set(val: string) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.$emit("commit", entity);
      },
      get(): Object {
        return get(this.entity, this.options.title) || "";
      },
    },
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      );
    },
    entityType(): string {
      return this.entity.entityType;
    },
  },
  watch: {
    async entityType() {
      this.scopes = await this.fetchScopes();
      this.entity[this.options.title] = [];
    },
  },
  methods: {
    getOptionValue(
      options: any[],
      val: string[],
      optionLabel = "title",
      optionValue = "id"
    ) {
      return val && options.length > 0
        ? val
            .map((e) => {
              return (options.find((el) => el[optionValue] === e) || {
                [optionLabel]: () => {
                  return this.$t("form.unknownValue");
                },
              })[optionLabel];
            })
            .join(", ") || this.$t("form.choose")
        : this.$t("form.choose");
    },
    async fetchScopes() {
      try {
        const { results } = await this.api.getEntitiesList({
          limiter: {
            type: "PermissionScope",
            limit: 1000,
          },
          filter: {
            q: [
              {
                key: "entityType",
                value: this.entityType,
                operator: "==",
              },
            ],
          },
        });
        return results;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    this.scopes = await this.fetchScopes();
  },
});
