
import Vue from "vue";
import EnumField from "./EnumField.vue";
import { mapStateTyped } from "@/store";

export default Vue.extend({
  name: "TypeField",
  components: {
    EnumField,
  },
  props: {
    entity: Object,
    options: Object,
  },
  computed: {
    ...mapStateTyped(["api"]),
  },
  data() {
    return {
      typesList: [] as any,
    };
  },
  methods: {
    async fetchEntityTypes() {
      try {
        const { results } = await this.api.getEntitiesList({
          limiter: {
            type: "EntityTypeMetadata",
            limit: 1000,
          },
          filter: {
            q: "entityType",
          },
        });
        this.typesList = results.map(({ entityType }: any) => ({
          value: entityType,
          title: entityType,
        }));
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.options.editable = [undefined, ""].includes(this.entity.entityId);
    this.fetchEntityTypes();
  },
});
