
import Vue from "vue";
import { coordinatesToPosition, locationToCoordinates, uuid } from "@/utils";
import DrawOnMap from "../map/DrawOnMap.vue";

export default Vue.extend({
  name: "FeaturesField",
  props: {
    entity: Object as () => ComplexObject,
    options: Object,
    context: Object,
  },
  components: {
    DrawOnMap,
  },
  data() {
    return {
      displayDeleteDialog: false,
      showMapDialog: false,
      notSavedValue: undefined as IFeature[] | undefined,
      notSavedMapView: null as MapView | null,
      drawPolygonTool: false,
    };
  },
  watch: {
    showMapDialog(val: boolean) {
      if (!val) {
        this.notSavedValue = undefined;
      }
    },
  },
  inject: ["defaultMapLocation", "isNewEntity"],
  computed: {
    features: {
      set(features: IFeature[]) {
        this.notSavedValue = features;
      },
      get(): any {
        return this.notSavedValue || this.localValue;
      },
    },
    localValue: {
      set(val: any) {
        const entity = { ...this.entity };
        if (entity[this.options.title] === undefined) {
          entity[this.options.title] = {};
        }
        entity[this.options.title].features = val;
        this.$emit("commit", entity);
      },
      get(): any[] {
        //@ts-ignore
        const features = [] as any;
        if (
          this.entity[this.options.title] &&
          Array.isArray(this.entity[this.options.title].features)
        ) {
          this.entity[this.options.title].features.forEach((feature: any) => {
            features.push({ ...feature, id: uuid() });
          });
        }
        return features;
      },
    },
    localTopViewSettings() {
      let initPosition = {} as MapView;
      if (this.context?.outline.topViewSettings) {
        const {
          heading,
          center,
          zoom,
          position,
          pitch,
          bearing,
        } = this.context?.outline.topViewSettings;
        initPosition = {
          center: center ?? [position?.lng, position?.lat],
          zoom: zoom ?? this.context.expandingZoom,
          bearing: bearing ?? heading,
          pitch,
        };
      } else {
        initPosition = {
          center: this.locationToCoordinates(this.context.location as string) ||
            this.locationToCoordinates(
              //@ts-ignore
              this.defaultMapLocation as string
            ) || [53.18575535, 50.08787507],
          zoom: 10,
          pitch: 0,
          bearing: 0,
        };
      }
      //@ts-ignore
      return initPosition;
    },
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      );
    },
  },
  methods: {
    saveAll() {
      if (this.notSavedValue) {
        this.localValue = this.notSavedValue;
      }
      this.showMapDialog = false;
    },
    coordinatesToPosition,
    locationToCoordinates,
  },
});
