
import Vue from "vue";
import { mapStateTyped } from "@/store";
import Tree from "primevue/tree";
import CreateEditPermission from "./common/CreateEditPermission.vue";

export default Vue.extend({
  name: "PermissionsTree",
  components: {
    Tree,
    CreateEditPermission,
  },
  props: {
    entity: Object,
  },
  data() {
    return {
      displayDeleteDialog: false,
      typePermissions: [] as any,
      entityPermissions: [] as any,
      permissionPriorityMeta: {} as any,
      currentPermissionPriority: {
        type: "PermissionPriority",
      } as PermissionPriority,
      names: new Map() as Map<string, string>,
      scopesNames: new Map() as Map<string, string>,
      expandedKeys: { "1": true, "0": true } as Record<string, boolean>,
      showPermissionPriorytyDialog: false,
      deletePermissionId: "",
    };
  },
  inject: ["entityType"],
  computed: {
    ...mapStateTyped(["api"]),
    nodes(): any {
      return [
        {
          key: "0",
          label: `${this.$t("permissions.default")}`,
          children: this.typePermissions.map(this.permissionsBuilder),
        },
        {
          key: "1",
          label: `${this.$t("permissions.special")}`,
          type: "addPermission",
          children: this.entityPermissions.map(this.permissionsBuilder),
        },
      ];
    },
  },
  methods: {
    onClose() {
      this.showPermissionPriorytyDialog = false;
      this.fetchAll();
    },
    async deleteEntity() {
      try {
        await this.api.deleteEntity({
          type: "PermissionPriority",
          id: this.deletePermissionId,
        });
        this.fetchAll();
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAll() {
      const typePermissions = await this.fetcTypePermissions();
      const entityPermissions = await this.fetcEntityPermissions();
      typePermissions.forEach(
        (permission) =>
          (permission.scopes = Array.from(new Set(permission.scopes)))
      );
      entityPermissions.forEach(
        (permission) =>
          (permission.scopes = Array.from(new Set(permission.scopes)))
      );
      for (const { groupId, userId } of [
        ...entityPermissions,
        ...typePermissions,
      ]) {
        const id = groupId || userId;
        const type = groupId ? "Group" : "User";
        id && this.names.set(id, await this.fetchEntityTitle(id, type));
      }
      this.entityPermissions = entityPermissions;
      this.typePermissions = typePermissions;
    },
    expandNode(key: string) {
      this.expandedKeys = {
        ...this.expandedKeys,
        [key]: !this.expandedKeys[key],
      };
    },
    permissionsBuilder(permission: PermissionPriority) {
      let permissionLabel = "";
      if (permission.groupId || permission.userId) {
        permissionLabel = permission.groupId
          ? `${this.$t("permissions.group")} - ${this.names.get(
              permission.groupId
            )} `
          : `${this.$t("permissions.user")} - ${this.names.get(
              permission.userId || ""
            )} `;
      } else {
        permissionLabel = `${this.$t("permissions.all")}`;
      }
      return {
        key: permission.id,
        permissionLabel,
        label: permissionLabel + permission.title,
        data: permission,
        type: "permissionTitle",
        children: [
          {
            key: `${permission.id}logic`,
            label: this.$t(`permissions.${permission.logic}`),
            data: permission.logic,
            type: "permissionLogic",
            children: permission.scopes.map((scopeId) => ({
              key: scopeId,
              label: this.scopesNames.get(scopeId),
            })),
          },
        ],
      };
    },
    expandAllNode(node: any) {
      this.expandedKeys = { ...this.expandedKeys, [node.key]: true };
      if (node.children && node.children.length) {
        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    expandAll() {
      for (let node of this.nodes) {
        this.expandAllNode(node);
      }
      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    async fetchEntityTitle(id: string, type: string) {
      try {
        const {
          entity: { title, fullName },
        } = await this.api.getEntity({ id, type });
        return title || fullName;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchEntityMetadata() {
      try {
        const { entity } = await this.api.getEntity({
          id: `EntityTypeMetadata:PermissionPriority`,
          type: "EntityTypeMetadata",
        });
        return entity;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchScopes() {
      try {
        const { results } = await this.api.getEntitiesList({
          limiter: {
            type: "PermissionScope",
            limit: 1000,
          },
          filter: {
            q: [
              {
                key: "entityType",
                value: this.entity.type,
                operator: "==",
              },
            ],
          },
        });
        return results;
      } catch (error) {
        console.error(error);
      }
    },
    async fetcTypePermissions() {
      try {
        const { results } = await this.api.getEntitiesList({
          limiter: {
            type: "PermissionPriority",
            limit: 1000,
          },
          filter: {
            q: [
              {
                key: "entityType",
                value: `${this.entity.type};!entityId`,
                operator: "==",
              },
            ],
          },
        });
        return (results || []) as PermissionPriority[];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async fetcEntityPermissions() {
      try {
        const { results } = await this.api.getEntitiesList({
          limiter: {
            type: "PermissionPriority",
            limit: 1000,
          },
          filter: {
            q: [
              {
                key: "entityId",
                value: this.entity.id,
                operator: "==",
              },
            ],
          },
        });
        return (results || []) as PermissionPriority[];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  },
  async mounted() {
    this.permissionPriorityMeta = await this.fetchEntityMetadata();
    const scopes = (await this.fetchScopes()) as {
      title: string;
      id: string;
    }[];
    scopes.forEach(({ id, title }) => {
      this.scopesNames.set(id, title);
    });
    this.fetchAll();
  },
});
