
import Vue from 'vue';

export default Vue.extend({
  name: "ErrorPlaceholder",
  props: {
    isReady: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    this.$emit('setReadyState', true)
  }
})
