
import Vue from "vue";
import { mapStateTyped } from "@/store";

export default Vue.extend({
  name: "ScheduleField",
  props: {
    entityType: String,
    entity: Object as () => ComplexObject,
    options: Object,
    context: Object,
  },
  computed: {
    ...mapStateTyped(["spaParent"]),
  },
  data() {
    return {
      showScheduleDialog: false,
      unmount: () => {},
    };
  },
  inject: ["entityType"],
  watch: {
    showScheduleDialog(val) {
      val ? this.mountDialog() : this.unmount();
    },
  },
  methods: {
    mountDialog() {
      const ref = this.$refs["ScheduleDialog"];
      const scheduleDialog = this.spaParent.areas.find((e) => e.name == "schedule");
      if (scheduleDialog !== undefined) {
        this.unmount = this.spaParent.mountChildren(
          ref,
          scheduleDialog.children.map((e) => {
            return {
              ...e,
              props: {
                ...e.props,
                tboContainerAreaId: this.entity.id,
                state: {
                  get: async () => {
                    return this.entity[this.options.title] || null;
                  },
                  set: async (value: string) => {
                    const entity = { ...this.entity };
                    entity[this.options.title] = value;
                    this.$emit("commit", entity);
                    return true;
                  },
                },
                onHide: () => {
                  this.showScheduleDialog = false;
                },
              },
            };
          })
        );
      }
    },
  },
  beforeDestroy() {
    this.unmount();
  },
});
