
import Mixins from "../../mixins";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { arrayMap } from "@/components/fieldsMap";
import { get, set } from "lodash-es";

type FieldOptions = {
  title: string;
  type: string;
  class: string;
  uniqueFields: string[];
  fields: { title: string; type: "enum" | "string" | "number" }[];
};

export default Mixins.extend({
  name: "ArrayField",
  props: {
    entity: Object,
    entityType: String,
    options: Object as () => FieldOptions,
  },
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      componentMap: arrayMap,
    };
  },
  computed: {
    context(): any {
      if (Array.isArray(this.options.uniqueFields)) {
        return {
          excludeOptions: this.options.uniqueFields.reduce(
            (acc, fieldTitle) => {
              return {
                ...acc,
                [fieldTitle]: this.localValue.map((e: any) => e[fieldTitle]),
              };
            },
            {}
          ),
        };
      } else {
        return {};
      }
    },
    localValue: {
      set(val: any) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.$emit("commit", entity);
      },
      get(): string[] {
        return get(this.entity, this.options.title) || [];
      },
    },
  },
  methods: {
    // te(str: string, def: string) {
    //   return this.$te(str) ? this.$t(str) : capitalize(def);
    // },
    getLabel(key: string) {
      return this.te(
        [
          `${this.entityType}.${key.replace(".", "")}`,
          `${this.entityType}.${key}`,
          `${this.entityType}.attrs.${key.replace(".", "")}`,
          `${this.entityType}.attrs.${key}`,
        ],
        key
      );
    },
    commit(field: any, value: any, title: string) {
      if (field.data[title] !== value[title]) {
        field.data[title] = value[title];
        const entity = { ...this.entity };
        this.$emit("commit", entity);
      }
    },
    addElement() {
      const entity = { ...this.entity };
      entity[this.options.title] = Array.isArray(entity[this.options.title])
        ? [
            ...entity[this.options.title],
            this.options.fields.reduce((acc, val) => ({
              ...acc,
              [val.title]: undefined,
            })),
          ]
        : [{}];
      this.$emit("commit", entity);
    },
    deleteElement({ index }: { index: number }) {
      const entity = { ...this.entity };
      entity[this.options.title].splice(index, 1);
      this.$emit("commit", entity);
    },
  },
  mounted() {},
});
