import Vue from "vue";
import { mapStateTyped } from "@/store";
import { capitalize } from "@/utils";

export default Vue.extend({
  name: "Mixins",
  computed: {
    ...mapStateTyped(["api"]),
  },
  data() {
    return {
      required: [],
    };
  },
  methods: {
    async fetchEntityMetadata(type: string) {
      try {
        const { results } = await this.api.getEntitiesList({
          limiter: {
            id: `EntityTypeMetadata:${type}`,
            type: "EntityTypeMetadata",
          },
        });
        const entity = Array.isArray(results) ? results[0] : results;
        //@ts-ignore
        this.$i18n.vm.messages[this.$i18n.locale] = {
          ...this.$i18n.messages[this.$i18n.locale],
          [type]: entity.locales?.ru || entity.localeRu,
        };
        this.required =
          entity.schemaCreate && entity.schemaCreate.required
            ? entity.schemaCreate.required
            : [];
        return entity;
      } catch (error) {
        console.error(error);
      }
    },
    te(strings: string[], def: string) {
      const str = strings.find((el) => this.$te(el));
      return str ? this.$t(str) : capitalize(def);
    },
    errorToast(error: any, message?: any) {
      console.error(error);
      this.$toast.add({
        severity: "error",
        summary: this.$t("message.error"),
        detail: message || error.message,
        life: 5000,
      });
    }
  },
});
