
import Vue from "vue";
import { mapStateTyped } from "@/store";
import { get, set } from "lodash-es";
import AutocompleteField from "../common/AutocompleteField.vue";

export default Vue.extend({
  name: "UsersField",
  components: {
    AutocompleteField,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      findUser: "",
      userIdForDelete: "",
      displayDeleteDialog: false,
      localLoading: false,
      usersNames: {} as Record<string, string>,
    };
  },
  computed: {
    ...mapStateTyped(["loading", "api"]),
    localValue: {
      set(val: string[]) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.$emit("commit", entity);
      },
      get(): string[] {
        return get(this.entity, this.options.title) || [];
      },
    },
  },
  methods: {
    updateValue() {
      this.$emit("update:value", this.localValue);
    },
    addUserInGroup(event: any) {
      if (event) {
        this.findUser = "";
        this.usersNames[event.value.id] = event.value.fullName;
        this.localValue = Array.from(
          new Set([event.value.id, ...this.localValue])
        );
        this.updateValue();
      }
    },
    deleteUserFromGroup() {
      if (![undefined, ""].includes(this.userIdForDelete)) {
        this.localValue = this.localValue.filter(
          (e) => e !== this.userIdForDelete
        );
      }
    },
    async fetchUsersNames() {
      this.$store.commit("setValue", ["loading", true]);
      this.localLoading = true;
      const users = await Promise.allSettled(
        this.entity[this.options.title].map(async (id: string) => {
          const { entity } = await this.api.getEntity({ id, type: "User" });
          return entity;
        })
      );
      //@ts-ignore
      users.forEach(({ value }) => {
        if (value) {
          this.usersNames[value.id] = value.fullName;
        }
      });
      this.$store.commit("setValue", ["loading", false]);
      this.localLoading = false;
    },
  },
  mounted() {
    if (this.entity[this.options.title]?.length) {
      this.fetchUsersNames();
    }
  },
});
