
import Vue from "vue";
import draggable from "vuedraggable";
import { createEditObject } from "@/utils";
import { mapStateTyped } from "@/store";
import { helpers, required } from "vuelidate/lib/validators";
import { cloneDeep } from "lodash-es";
import ErrorMesseges from "../common/ErrorMesseges.vue";
import JSONField from "./JSONField.vue";

const schemaPattern = helpers.regex("schemaPattern", /^[^&?#<>/"'=;()]+$/);

export default Vue.extend({
  name: "ComplexObjectStructure",
  components: {
    draggable,
    ErrorMesseges,
    JSONField,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      currentLayer: this.entity.layersOrder ? this.entity.layersOrder[0] : "",
      currentEditingEntity: {
        fields: [],
        entity: {} as any,
        context: {},
        onSave: () => {},
      },
      deleteElement: {} as any,
      displayDeleteDialog: false,
      currentDeleteFunc: () => {},
      nowEditingColumn: [] as string[],
      entities: [] as any,
      originalObject: {} as any,
      newEntityColumn: [] as string[],
      newEntity: {} as any,
      currentAreaId: null as string | null,
      currentObject: {} as any,
      layerSubstrate: [] as any[],
      innerAreas: [] as any[],
      metadata: {} as Record<string, any>,
      changeMap: new Map() as Map<string, string>,
    };
  },
  schema: [
    {
      mountPoint: "currentEditingEntity",
      schema: {},
    },
  ],
  validations(): any {
    const validators = {} as Record<string, any>;
    const type = this.currentEditingEntity.entity.type;
    const meta = type ? this.metadata[type] : undefined;
    if (type && meta && meta.viewDialog && meta.viewDialog.tabs) {
      const fields = this.metadata[type].viewDialog.tabs.reduce(
        (acc: any[], { fields }: any) => {
          return [...acc, ...fields];
        },
        []
      );
      fields.forEach((option: any) => {
        if (["string", "text"].includes(option.type)) {
          validators[option.title] = { schemaPattern };
        }
        if (option.type === "reference") {
          validators[option.title] = {
            ...validators[option.title],
            isReferenceValid: async (id: string) => {
              if (["", undefined].includes(id)) return true;
              if (this.changeMap.get(option.title) === id) return true;
              try {
                const { entity } = await this.api.getEntity({
                  id,
                  type: option.entityType,
                });
                this.changeMap.set(option.title, id);
                return entity.type === option.entityType;
              } catch (error) {
                console.error(error);
                return false;
              }
            },
          };
        }
      });
    }
    if (type && meta) {
      const requiredFields: string[] =
        meta.schemaCreate && meta.schemaCreate.required;
      if (requiredFields) {
        requiredFields.forEach((key) => {
          validators[key] = { ...validators[key], required };
        });
      }
    }
    return { currentEditingEntity: { entity: validators } };
  },
  computed: {
    ...mapStateTyped(["api"]),
    columns(): any[] {
      const featuresObject =
        this.layerSubstrate.find(
          ({ layerName, type }) =>
            type === this.options.layerSubstrate.entityType &&
            this.currentLayer === layerName
        ) || [];
      const features =
        featuresObject && featuresObject.features
          ? featuresObject.features
          : [];
      const { outline } = this.currentArea;
      const border =
        outline && Array.isArray(outline.border)
          ? [
              {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: outline.border,
                },
              },
            ]
          : [];
      return [
        // layers
        {
          name: "layers",
          style: { "flex-grow": 0 },
          draggable: true,
          sortable: true,
          list: this.entity.layersOrder
            ? this.entity.layersOrder.map((layer: string) => {
                const features = this.layerSubstrate.find(
                  ({ layerName }: any) => layer === layerName
                );
                const id = features ? features.id : undefined;
                return {
                  title: layer,
                  id,
                  features,
                };
              })
            : [],
          getEntityFields: () =>
            this.options.layerSubstrate && this.options.layerSubstrate.fields
              ? this.options.layerSubstrate.fields
              : [],
          context: {
            outline: this.entity["outline"],
            expandingZoom: this.entity["expandingZoom"],
            location: this.entity["location"],
          },
          onClick: ({ title }: any) => {
            this.currentLayer = title;
          },
          onDelete: async ({ title, features }: any) => {
            if (features && features.id) {
              await this.deleteEntity(
                features.id,
                this.options.layerSubstrate.entityType
              );
            }
            const entity = { ...this.entity };
            entity.layersOrder = entity.layersOrder.filter(
              (e: string) => e !== title
            );
            this.$emit("commit", entity);
            this.fetchSubstrate();
          },
          onSave: async () => {
            const features = { ...this.currentEditingEntity.entity.features };
            if (features.id === undefined) {
              features.layerName = this.currentLayer;
              features.servicePath = this.entity.id;
              features.type = this.options.layerSubstrate.entityType;
              this.currentEditingEntity.entity.features = features;
            }
            const { title } = this.currentEditingEntity.entity;
            if (this.currentLayer !== title) {
              this.entity.layersOrder.splice(
                this.entity.layersOrder.indexOf(this.currentLayer),
                1,
                title
              );
              this.currentEditingEntity.entity.features.layerName = title;
              for (const area of this.areas) {
                await createEditObject(
                  { ...area, parentLayerName: title },
                  area,
                  () => {},
                  this.api
                );
              }
              const objects = this.entities.filter(
                ({ layerName }: any) => layerName === this.currentLayer
              );
              for (const object of objects) {
                await createEditObject(
                  { ...object, layerName: title },
                  object,
                  () => {},
                  this.api
                );
              }
            }
            await createEditObject(
              this.currentEditingEntity.entity.features,
              {
                id: this.originalObject.id,
                type: this.options.layerSubstrate.entityType,
              },
              () => {},
              this.api
            );
            const method =
              this.entity.layersOrder === undefined
                ? "appendEntity"
                : "updateEntity";
            await this.api[method]({
              id: this.entity.id,
              layersOrder: this.entity.layersOrder,
              type: this.entity.type,
            });
            await this.fetchSubstrate();
            await this.fetchAreas();
            await this.fetchObjects();
          },
          createNewEntity: (column: any) => {
            const title = this.newEntity.title;
            if (this.entity.layersOrder === undefined) {
              this.entity.layersOrder = [];
            }
            this.entity.layersOrder.push(title);
            const newFeatures = { layerName: title, features: [] };
            this.layerSubstrate.push(newFeatures);
            this.setEditingEntity(column, {
              title,
              features: newFeatures,
            });
          },
          addButtonDisabled: () => false,
          isActive: ({ title }: any) => this.currentLayer === title,
          getLabel: ({ title }: any) => title,
          createSchema: [
            {
              title: "title",
              type: "string",
              class: "p-lg-12",
            },
          ],
          createValid: (entity: any) => {
            return (
              typeof entity.title === "string" &&
              this.entity.layersOrder &&
              !this.entity.layersOrder.includes(entity.title)
            );
          },
          onSort: ({ newIndex, oldIndex }: any) => {
            const entity = { ...this.entity };
            if (newIndex !== oldIndex) {
              const layer = entity.layersOrder[oldIndex];
              entity.layersOrder.splice(oldIndex, 1);
              entity.layersOrder.splice(newIndex, 0, layer);
            }
            this.$emit("commit", entity);
          },
        },
        // areas
        {
          name: "areas",
          style: { "flex-grow": 2 },
          draggable: false,
          sortable: false,
          list: this.areas,
          getEntityFields: ({ areaType, type }: any) => {
            const { fields } =
              this.options.innerAreas.find(
                ({ areaType: a, entityType: t }: any) =>
                  t === type && a === areaType
              ) || {};
            return fields || [];
          },
          onClick: ({ id }: ComplexObject) => {
            this.currentAreaId = id;
          },
          onDelete: async (area: any) => {
            try {
              if (area.id) {
                await this.deleteEntity(area.id, area.type);
              }
              this.innerAreas = this.innerAreas.filter(
                (innerArea: ComplexObject) => innerArea !== area
              );
            } catch (error) {
              console.error(error);
            }
          },
          context: {
            features: [...features, ...border],
            outline: this.entity["outline"],
            expandingZoom: this.entity["expandingZoom"],
            location: this.entity["location"],
            onClose: async (edited: any) => {
              if (edited) {
                await this.fetchAreas();
                edited.type = "ComplexObject";
                this.currentEditingEntity.entity = edited;
              }
            },
          },
          createSchema: [
            {
              title: "title",
              type: "string",
              class: "p-lg-12",
            },
            {
              title: "type",
              type: "enum",
              class: "p-lg-12",
              options: [
                { value: "area", title: "area" },
                { value: "nestedComplexObject", title: "nestedComplexObject" },
              ],
            },
          ],
          createValid(entity: any) {
            return (
              typeof entity.title === "string" &&
              typeof entity.type === "string"
            );
          },
          onSave: async () => {
            const entity = { ...this.currentEditingEntity.entity };
            if (entity.areaType === "area") {
              entity.expandingZoom = 0;
            }
            delete entity.areaType;
            await createEditObject(
              entity,
              this.originalObject,
              () => {},
              this.api
            );
            this.fetchAreas();
          },
          createNewEntity: (column: any) => {
            const newEntity = {
              title: this.newEntity.title,
              areaType: this.newEntity.type,
            } as any;
            const field =
              this.options.innerAreas.find(
                ({ title }: any) => title === this.newEntity.type
              ) || {};
            newEntity.type = field.entityType;
            this.originalObject = cloneDeep(newEntity);
            newEntity.servicePath = this.entity.id;
            newEntity.parentLayerName = this.currentLayer;
            newEntity.location = this.entity.location;
            this.innerAreas.push(newEntity);
            this.setEditingEntity(column, newEntity);
          },
          addButtonDisabled: () => this.currentLayer === undefined,
          isActive: ({ id }: ComplexObject) => this.currentArea.id === id,
          getLabel: ({ title }: ComplexObject) =>
            title || this.$t(`${this.entity.type}columns.area`),
        },
        // objects
        {
          name: "objects",
          style: { "flex-grow": 2 },
          draggable: true,
          sortable: false,
          list: this.areaObjects,
          onClick() {},
          onSave: async () => {
            await createEditObject(
              this.currentEditingEntity.entity,
              this.originalObject,
              () => {},
              this.api
            );
            this.fetchObjects();
          },
          onDelete: async ({ id, type }: any) => {
            try {
              if (id) {
                await this.deleteEntity(id, type);
              }
              this.entities = this.entities.filter(
                ({ id: entityId }: any) => entityId !== id
              );
            } catch (error) {
              console.error(error);
            }
          },
          context: {
            features: [...features, ...border],
            outline: this.currentArea["outline"],
            expandingZoom: this.currentArea["expandingZoom"],
            location: this.currentArea["location"],
          },
          getEntityFields: ({ type }: any) => {
            const { fields } =
              this.options.innerObjects.find(
                ({ entityType }: any) => entityType === type
              ) || {};
            return fields || [];
          },
          createSchema: [
            {
              title: "title",
              type: "string",
              class: "p-lg-12",
            },
            {
              title: "type",
              type: "enum",
              class: "p-lg-12",
              options: this.options.innerObjects.map((object: any) => {
                return {
                  value: object.entityType,
                  title: object.entityType,
                };
              }),
            },
          ],
          createValid(entity: any) {
            return (
              typeof entity.title === "string" &&
              typeof entity.type === "string"
            );
          },
          createNewEntity: (column: any) => {
            const newEntity = cloneDeep(this.newEntity);
            newEntity.servicePath = this.currentArea.id;
            newEntity.layerName = this.currentLayer;
            this.originalObject = cloneDeep(newEntity);
            this.entities.push(newEntity);
            this.setEditingEntity(column, newEntity);
          },
          onSort: async (event: any) => {
            const {
              item: {
                dataset: { id: targetId },
              },
            } = event;
            if (
              event.originalEvent &&
              Array.isArray(event.originalEvent.path) &&
              targetId
            ) {
              for (const { dataset } of event.originalEvent
                .path as HTMLElement[]) {
                if (dataset === undefined) continue;
                const { type, id } = dataset;
                if (type === "area" && id) {
                  const entity = this.entities.find(
                    ({ id: entityId }: any) => entityId === targetId
                  );
                  if (entity.servicePath !== id) {
                    this.cancelEditingEntity();
                    await createEditObject(
                      { ...entity, servicePath: id },
                      entity,
                      () => {},
                      this.api
                    );
                    this.fetchObjects();
                  }
                }
              }
            }
          },
          addButtonDisabled: () =>
            this.currentArea.id === undefined ||
            this.currentArea.areaType === "nestedComplexObject",
          isActive: ({ id }: any) => this.currentEditingEntity.entity.id === id,
          getLabel: ({ title }: any) =>
            title || this.$t(`${this.entity.type}.columns.object`),
        },
      ];
    },
    areas(): ComplexObject[] {
      if (this.innerAreas.length > 0) {
        return this.innerAreas.filter(({ parentLayerName }: any) => {
          return parentLayerName === this.currentLayer;
        });
      }
      return [];
    },
    areaObjects(): any {
      if (this.currentArea.id) {
        return this.entities.filter(
          ({ servicePath }: any) => this.currentArea.id === servicePath
        );
      }
      return [];
    },
    currentArea(): Partial<ComplexObject> {
      if (this.currentAreaId !== null) {
        const area = this.areas.find(({ id }) => id === this.currentAreaId);
        return area || {};
      } else {
        return this.areas.length > 0 ? this.areas[0] : {};
      }
    },
    fieldsLocales(): Record<string, string> {
      const locale =
        this.metadata[this.currentEditingEntity.entity.type]?.locales?.ru ||
        this.metadata[this.currentEditingEntity.entity.type]?.localeRu?.attrs;
      if (this.currentEditingEntity.entity.type && locale) {
        return locale;
      }
      return {};
    },
    localValue: {
      set(val: object) {
        const entity = { ...this.entity };
        entity[this.options.title] = this.options.isArray
          ? Object.values(JSON.parse(JSON.stringify(val)))
          : val;
        this.$emit("commit", entity);
      },
      get(): Object {
        return this.entity[this.options.title];
      },
    },
  },
  methods: {
    commit(entity: any) {
      this.$set(this.currentEditingEntity, "entity", entity);
    },
    async deleteEntity(id: string, type: string) {
      try {
        return await this.api.deleteEntity({ type, id });
      } catch (error) {
        console.error(error);
      }
    },
    cancelEditingEntity() {
      this.currentEditingEntity = {
        fields: [],
        entity: {},
        context: {},
        onSave: () => {},
      };
      this.$schema = [
        {
          mountPoint: "currentEditingEntity",
          schema: {},
        },
      ];
    },
    cancelAddEntity(columName: string) {
      this.newEntityColumn = this.newEntityColumn.filter(
        (e) => e !== columName
      );
    },
    async fetchMeta(type: string) {
      try {
        const { entity } = await this.api.getEntity({
          id: `EntityTypeMetadata:${type}`,
          type: "EntityTypeMetadata",
        });
        this.metadata[type] = entity;
        const { schemaCreate } = this.metadata[type];
        if (schemaCreate.properties) {
          for (const [key, property] of Object.entries(
            schemaCreate.properties
          ) as [string, any]) {
            if (property.$ref) {
              try {
                const {
                  entity: { schemaCreate: schemaCreateInternal },
                } = await this.api.getEntity({
                  id: `EntityTypeMetadata:${property.$ref}`,
                  type: "EntityTypeMetadata",
                });
                schemaCreate.properties[key] = schemaCreateInternal || {};
              } catch (error) {
                console.error(error);
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAreas() {
      this.innerAreas = [];
      const innerAreas = this.options.innerAreas ?? [];
      for (const { areaType, entityType } of innerAreas) {
        const { results } = await this.api.getEntitiesList({
          limiter: {
            type: entityType,
            limit: 1000,
          },
          filter: {
            q: [
              {
                key: "servicePath",
                value: this.entity.id,
                operator: "==",
              },
            ],
          },
        });
        if (Array.isArray(results)) {
          results.forEach((result: any) => {
            const entity = { ...result, areaType };
            this.innerAreas.push(entity);
          });
          this.fetchMeta(entityType);
        }
      }
    },
    async fetchSubstrate() {
      this.layerSubstrate = [];
      const { results } = await this.api.getEntitiesList({
        limiter: {
          type: this.options.layerSubstrate.entityType,
          limit: 1000,
        },
        filter: {
          q: [
            {
              key: "servicePath",
              value: this.entity.id,
              operator: "==",
            },
          ],
        },
      });
      this.layerSubstrate = results;
      this.fetchMeta(this.options.layerSubstrate.entityType);
    },
    async fetchObjects() {
      this.entities = [];
      const innerObjectsTypes = this.options.innerObjects
        ? this.options.innerObjects.map(({ entityType }: any) => entityType)
        : [];
      if (innerObjectsTypes) {
        innerObjectsTypes.forEach((type: string) => this.fetchMeta(type));
      }
      this.innerAreas
        .filter(({ outline, expandingZoom }) => outline && expandingZoom === 0)
        .forEach(async (area) => {
          try {
            const { results: entities } = await this.api.getEntitiesList({
              limiter: {
                type: [...innerObjectsTypes].join(","),
                limit: 1000,
              },
              filter: {
                q: [
                  {
                    key: "servicePath",
                    value: area.id,
                    operator: "==",
                  },
                ],
              },
            });
            this.entities = [...this.entities, ...entities];
          } catch (error) {
            console.error(error);
          }
        });
    },
    async setValidationSchema(type: string) {
      const schema = {} as any;
      const { schemaCreate } = this.metadata[type]
        ? this.metadata[type]
        : { schemaCreate: {} };
      if (schemaCreate) {
        this.$schema = [
          {
            mountPoint: "currentEditingEntity",
            schema: {},
          },
        ];
        schema.mountPoint = `currentEditingEntity.entity`;
        delete schemaCreate.additionalProperties;
        // delete schemaCreate.required;
        delete schemaCreate.type;
        schema.schema = schemaCreate;
      }
      this.$schema.push(schema);
    },
    setEditingEntity(column: any, element: any) {
      this.currentEditingEntity = {
        fields: column.getEntityFields(element),
        entity: element,
        context: column.context,
        onSave: column.onSave,
      };
      this.setValidationSchema(element.type);
    },
    clone: cloneDeep,
  },
  async mounted() {
    try {
      if (this.options.layerSubstrate) {
        await this.fetchSubstrate();
        await this.fetchAreas();
        await this.fetchObjects();
      }
    } catch (error) {
      console.error(error);
    }
  },
});
