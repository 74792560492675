
import Vue from "vue";
import { mapStateTyped } from "@/store";

export default Vue.extend({
  name: "CreateEditPermission",
  props: {
    entityType: String,
    entity: Object as () => Record<string, any>,
    onClose: Function,
  },
  computed: {
    ...mapStateTyped(["spaParent"]),
  },
  data() {
    return {
      unmount: () => {},
    };
  },
  methods: {
    async mountDialog() {
      const ref = this.$refs["AdminDialog"];
      const adminDialog = this.spaParent.areas.find((e) => e.name == "create-permission");
      if (adminDialog !== undefined) {
        this.unmount = this.spaParent.mountChildren(
          ref,
          adminDialog.children.map((e) => {
            return {
              ...e,
              props: {
                ...e.props,
                entityType: this.entityType,
                entity: this.entity,
                onClose: this.onClose,
                hidePermissions: true,
              },
            };
          })
        );
      }
    },
  },
  mounted() {
    this.mountDialog();
  },
  beforeDestroy() {
    this.unmount();
  },
});
