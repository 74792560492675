var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"p-d-flex",staticStyle:{"padding":"0 !important","margin-top":"2.7rem"}},[_c('Button',{staticClass:"p-button-outlined",attrs:{"icon":"mdi mdi-18px mdi-shape-polygon-plus","data-cy":`${_vm.entity.type}_${_vm.options.title}`},on:{"click":function($event){_vm.showMapDialog = true}}}),(_vm.showMapDialog)?_c('Dialog',{style:({
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
      maxHeight: '100vh',
      borderRadius: '0',
    }),attrs:{"visible":"","position":"center","showHeader":false,"dismissableMask":true,"closable":true,"modal":true},on:{"close":function($event){_vm.showMapDialog = false}}},[_c('div',{staticStyle:{"position":"relative"}},[_c('DrawOnMap',{attrs:{"mapView":_vm.localTopViewSettings,"mode":"single","editableGeometries":_vm.features,"substrateGeometries":_vm.substrateFeatures,"tools":['Sector']},on:{"update:editableGeometries":function($event){_vm.features=$event},"update:editable-geometries":function($event){_vm.features=$event}}})],1),_c('Moveable',_vm._b({staticClass:"camera",on:{"drag":_vm.handleDrag}},'Moveable',_vm.moveable,false),[(_vm.entity.id)?_c('CameraCard',{attrs:{"camera":_vm.entity}}):_vm._e()],1),_c('div',{staticStyle:{"position":"absolute","bottom":"2rem","left":"2rem"}},[_c('Button',{staticClass:"p-mr-2",attrs:{"label":_vm.$t('button.ok'),"data-cy":`${_vm.entity.type}_${_vm.options.title}_save`,"disabled":!(_vm.options.editable === undefined || _vm.options.editable) ||
          !_vm.notSavedValue?.length},on:{"click":_vm.saveAll}}),_c('Button',{staticClass:"p-button-secondary p-button-outlined",attrs:{"label":_vm.$t('button.cancel'),"data-cy":`${_vm.entity.type}_${_vm.options.title}_cancel`},on:{"click":function($event){_vm.showMapDialog = false}}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }