
import Vue from "vue";
import { mapStateTyped } from "@/store";

export default Vue.extend({
  name: "CameraCard",
  props: {
    camera: Object,
  },
  computed: {
    ...mapStateTyped(["spaParent"]),
  },
  data() {
    return {
      unmount: () => {},
    };
  },
  methods: {
    initPlayer() {
      let ref = this.$refs[`player-${this.camera.id}`];
      if (Array.isArray(ref)) ref = ref[0];
      const player = this.spaParent.areas.find((e) => e.name === "player");
      if (player) {
        this.unmount = this.spaParent.mountChildren(
          ref,
          player.children.map((e) => {
            return {
              ...e,
              props: { camera: { id: this.camera.id }, ratio: "fit" },
            };
          })
        );
      }
    },
  },
  mounted() {
    this.initPlayer();
  },
  destroyed() {
    this.unmount();
  },
});
