
import { mapMutations } from "vuex";
import { mapStateTyped } from "@/store";
import Vue from "vue";
import OverlayPanel from "primevue/overlaypanel";

export default Vue.extend({
  name: "LayersMenu",
  props: {
    currentLayerName: String,
  },
  components: { OverlayPanel },
  computed: {
    ...mapStateTyped(["spaParent"]),
    layersButtons(): string[] {
      return this.spaParent?.props?.layers
        ? [
            "default",
            ...this.spaParent?.props?.layers.map(({ title }: any) => title),
          ]
        : [];
    },
  },
  methods: {
    ...mapMutations(["setValue"]),
    menuToggle(ref: string, event: EventInit) {
      // @ts-ignore
      this.$refs[ref].toggle(event);
    },
    setLayerName(name: string) {
      localStorage.setItem("savedLayerName", name);
      this.$emit("update:currentLayerName", name);
    },
  },
});
