
import Vue from "vue";
import { mapStateTyped } from "@/store";
import { get, set } from "lodash-es";
import InputMask from "primevue/inputmask";

export default Vue.extend({
  name: "AddressField",
  components: {
    InputMask,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapStateTyped(["strictRegEx"]),
    localValue: {
      set(val: Record<string, string>) {
        const entity = { ...this.entity };
        entity[this.options.title] = val;
        this.$emit("commit", entity);
      },
      get(): Record<string, string> {
        return this.entity[this.options.title] || {};
      },
    },
  },
  mounted() {
    if (get(this.entity, this.options.title) === undefined) {
      set(this.entity, this.options.title, this.localValue);
    }
  },
  methods: {
    setOrRemoveAddressField(val: string, key: string) {
      if (!val.length) {
        const l = { ...this.localValue };
        delete l[key];
        this.localValue = l;
      } else {
        this.localValue = {
          ...this.localValue,
          [key]: val.replace(this.strictRegEx, ""),
        };
      }
    },
  },
});
