
import Vue from "vue";
import Dropdown from "primevue/dropdown";
import { get, set } from "lodash-es";

type Option = {
  title: string;
  value: string;
};

interface FieldOptions {
  title: string;
  type: "enum";
  editable: boolean;
  showLabel: boolean;
  options: Option[];
}

type Context = {
  excludeOptions?: {
    [key in FieldOptions["title"]]: Option["value"][];
  };
};

export default Vue.extend({
  name: "EnumField",
  components: {
    Dropdown,
  },
  props: {
    entity: Object,
    options: Object as () => FieldOptions,
    context: Object as () => Context,
  },
  inject: ["entityType"],
  methods: {
    getLocale(val: any): string {
      if (this.options?.options) {
        const option = this.options.options.find(
          ({ value }: any) => value === val
        );

        if (option) {
          return option.title
            // @ts-ignore
            ? String(this.$t(`${this.entityType}.enumOptions.${option.title}`))
            // @ts-ignore
            : String(this.$t(`${this.entityType}.enumOptions.${this.options.title}.${option.value}`))
        }

        return ""
      } else {
        // @ts-ignore
        return String(this.$t(`${this.entityType}.enumOptions.${this.options.title}.${this.entity[this.options.title]}`))
      }
    },
  },
  computed: {
    calcOptions(): Option[] {
      if (
        Array.isArray(get(this.context?.excludeOptions, this.options.title))
      ) {
        return this.options.options.filter(
          ({ value }) =>
            !get(this.context?.excludeOptions, this.options.title)?.includes(
              value
            )
        );
      } else {
        return this.options.options;
      }
    },
    localValue: {
      set(val: string) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.$emit("commit", entity);
      },
      get(): Object {
        return get(this.entity, this.options.title) || "";
      },
    },
  },
  data() {
    return {};
  },
});
