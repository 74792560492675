
import Vue from "vue";
import MapTalksMap from "../map/MapTalksMap.vue";
import TMarker from "../map/TMarker.vue";
import TPolygon from "../map/TPolygon.vue";
import TLayer from "../map/TLayer.vue";
import { get, set } from "lodash-es";
import {
  coordinatesToLocation,
  locationToCoordinates,
  unbreakable,
} from "@/utils";

const coordsRegEx = /^-?\d{1,3}\.\d{3,}, -?\d{1,3}\.\d{3,}$/;
export default Vue.extend({
  name: "DeviceLocationField",
  components: {
    MapTalksMap,
    TMarker,
    TPolygon,
    TLayer,
  },
  props: {
    entity: Object,
    options: Object,
    context: Object,
  },
  data() {
    return {
      showMapDialog: false,
      location: "" as string,
      localValueInputed: "" as string | number[],
    };
  },
  watch: {
    localValueInputed(val: string) {
      const clearedVal = val.replace(/[^0-9.,-\s]/g, "");
      if (val.length === clearedVal.length && this.validCoordinates) {
        this.localValue = val;
      } else {
        this.localValueInputed = clearedVal;
      }
    },
  },
  inject: ["defaultMapLocation", "isNewEntity"],
  computed: {
    validCoordinates(): boolean {
      return coordsRegEx.test(this.localValueInputed as string);
    },
    localTopViewSettings() {
      let initPosition = {} as MapView;
      if (this.context.outline && this.context.outline.topViewSettings) {
        const { heading, pitch, position } =
          this.context.outline.topViewSettings;
        initPosition = {
          center: this.locationToCoordinates(
            this.entity[this.options.title]
          ) || [position.lng, position.lat],
          zoom: this.context.expandingZoom,
          bearing: heading,
          pitch,
        };
      } else {
        initPosition = {
          center: this.locationToCoordinates(this.context.location as string) ||
            this.locationToCoordinates(
              //@ts-ignore
              this.defaultMapLocation as string
            ) || [53.18575535, 50.08787507],
          zoom: 17,
          pitch: 0,
          bearing: 0,
        };
      }
      //@ts-ignore
      return initPosition;
    },
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      );
    },
    localValue: {
      set(val: string) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.location = this.localValueInputed = val;
        this.$emit("commit", entity);
      },
      get(): string {
        //@ts-ignore
        return (
          get(this.entity, this.options.title) ||
          this.context["location"] ||
          //@ts-ignore
          this.defaultMapLocation
        );
      },
    },
    substrateFeatures() {
      let features = [] as any;
      if (this.context && Array.isArray(this.context.features)) {
        features = [...this.context.features];
      }
      if (this.context && this.context.outline && this.context.outline.border) {
        features.push({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [this.context["outline"].border],
          },
        });
      }
      return features;
    },
  },
  methods: {
    viewChange({ x, y }: { x: number; y: number }) {
      this.location = this.coordinatesToLocation([x, y]);
    },
    unbreakable,
    coordinatesToLocation,
    locationToCoordinates,
  },
  beforeMount() {
    this.location = this.localValueInputed = this.localValue;
  },
});
