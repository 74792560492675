
import Vue from "vue";
import { coordinatesToPosition, locationToCoordinates } from "@/utils";
import DrawOnMap from "../map/DrawOnMap.vue";
export default Vue.extend({
  name: "BorderField",
  props: {
    entity: Object as () => ComplexObject,
    options: Object,
    context: Object,
  },
  components: {
    DrawOnMap,
  },
  data() {
    return {
      counter: 0,
      displayDeleteDialog: false,
      showMapDialog: false,
      notSavedValue: undefined as IFeature[] | undefined,
      currentMapView: {} as MapView,
      drawPolygonTool: false,
    };
  },
  watch: {
    showMapDialog(val: boolean) {
      if (!val) {
        this.currentMapView = this.localTopViewSettings;
        this.notSavedValue = undefined;
      }
    },
  },
  inject: ["defaultMapLocation", "entityType"],
  computed: {
    features: {
      set(features: IFeature[]) {
        this.notSavedValue = features;
      },
      get(): any {
        return this.notSavedValue === undefined
          ? this.localValue.length > 0
            ? [
                {
                  type: "Feature",
                  id: this.entity.id,
                  geometry: {
                    type: "Polygon",
                    coordinates: this.localValue,
                  },
                },
              ]
            : []
          : this.notSavedValue;
      },
    },
    substrateFeatures() {
      let features = [] as any;
      if (this.context && Array.isArray(this.context.features)) {
        features = [...this.context.features];
      }
      if (this.context && this.context.outline && this.context.outline.border) {
        features.push({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [this.context["outline"].border],
          },
        });
      }
      if (this.entity.location && this.entity.title) {
        features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [locationToCoordinates(this.entity.location)],
          },
          properties: {
            label: this.entity.title,
          },
        });
      }
      return features;
    },
    localValue: {
      set(val: any) {
        const entity = { ...this.entity };
        entity.outline[this.options.title] = val;
        this.$emit("commit", entity);
      },
      get(): string {
        //@ts-ignore
        return this.entity.outline
          ? this.entity.outline[this.options.title] || []
          : [];
      },
    },
    localTopViewSettings: {
      set(val: MapView) {
        const entity = { ...this.entity };
        const { center, zoom, pitch, bearing } = val;
        entity.outline.topViewSettings = {
          center,
          zoom,
          pitch,
          bearing,
        };
        if (entity.expandingZoom === undefined) {
          entity.expandingZoom = zoom;
        }
        this.$emit("commit", entity);
      },
      get(): MapView {
        let initPosition = {} as MapView;
        if (this.entity.outline && this.entity.outline.topViewSettings) {
          const {
            heading,
            center,
            zoom,
            position,
            pitch,
            bearing,
          } = this.entity.outline.topViewSettings;
          initPosition = {
            center: center ?? [position?.lng, position?.lat],
            zoom: zoom ?? this.entity.expandingZoom,
            bearing: bearing ?? heading,
            pitch,
          };
        } else {
          initPosition = {
            center: this.locationToCoordinates(
              this.entity.location as string
            ) ||
              this.locationToCoordinates(
                //@ts-ignore
                this.defaultMapLocation as string
              ) || [53.18575535, 50.08787507],
            zoom: 10,
            pitch: 0,
            bearing: 0,
          };
        }
        //@ts-ignore
        return initPosition;
      },
    },
  },
  methods: {
    saveAll() {
      if (this.notSavedValue && this.notSavedValue[0]) {
        this.localValue = this.notSavedValue[0].geometry.coordinates[0];
        this.notSavedValue = undefined;
      }
      this.showMapDialog = false;
    },
    coordinatesToPosition,
    locationToCoordinates,
  },
  beforeMount() {
    this.currentMapView = this.localTopViewSettings;
    if (this.entity.outline === undefined) {
      this.entity.outline = {
        border: [],
      };
    }
  },
});
