
import Vue from "vue";
//@ts-ignore
import { Sector } from "maptalks.plotsymbol";
//@ts-ignore
import { Coordinate, measurer, Sector as SectorMap } from "maptalks";
import { findParentLayer, pointsToAnglesSector, uuid } from "@/utils";

export default Vue.extend({
  name: "TSector",
  props: {
    visible: { type: Boolean, required: false, default: undefined },
    center: Array,
    radius: Number,
    startAngle: Number,
    endAngle: Number,
    options: Object,
    zIndex: Number,
    editing: Boolean,
  },
  data: () => ({
    layer: {} as any,
    parentMap: {} as any,
    sector: {} as any,
    sectorMap: {} as any,
  }),
  watch: {
    editing(val) {
      if (val) {
        this.sector.startEdit();
      } else {
        this.sector.endEdit();
      }
    },
    visible(val) {
      if (val) {
        this.layer.show();
      } else {
        this.layer.hide();
      }
    },
  },
  methods: {
    getSectorPoints(
      center: number[],
      radius: number,
      startAngleDeg: number,
      endAngleDeg: number
    ) {
      const startAngle = (startAngleDeg * Math.PI) / 180,
        endAngle = (endAngleDeg * Math.PI) / 180;
      let points = [];
      const _measurer = measurer.Measurer.getInstance();
      for (let angle of [endAngle, startAngle]) {
        const dx = radius * Math.cos(angle),
          dy = radius * Math.sin(angle);
        const vertex = _measurer.locate(center, dx, dy);
        points.push(new Coordinate([vertex["x"], vertex["y"]]));
      }
      return points;
    },
  },
  async mounted() {
    const layer = findParentLayer(this);
    Sector.registerJSONType("Sector");
    SectorMap.registerJSONType("SectorMap");
    const center = new Coordinate(this.center);
    const pList = this.getSectorPoints(
      center,
      20,
      this.startAngle,
      this.endAngle
    );
    const points = [center, pList[0], pList[1]];
    this.sector = new Sector(points, {
      symbol: {
        opacity: 1,
      },
    });
    this.sectorMap = new SectorMap(
      center,
      20,
      this.endAngle || 0,
      this.startAngle,
      this.options
    );
    layer.addGeometry([this.sector, this.sectorMap]);
    if (this.options && this.options.editable) {
      if (this.editing) {
        this.sector.startEdit();
      }
      this.sector.on("editrecord", ({ target }: any) => {
        const coordinates = target.getCoordinates();
        if (coordinates.length === 3) {
          const result = pointsToAnglesSector(target._prjCoords);
          if (result) {
            result.id = uuid();
            result.center = [coordinates[0].x, coordinates[0].y];
            this.$emit("updateShape", result);
          }
        }
      });
    }
    this.sector.on("click dblclick touchend", () => {
      this.$emit("click");
    });
  },
  destroyed() {
    this.sector.remove();
    this.sectorMap.remove();
  },
});
