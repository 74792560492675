var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Dialog',{style:({
    width: '80vw',
    height: '85vh',
  }),attrs:{"visible":_vm.visible,"position":"center","header":_vm.dialogHeader,"closeOnEscape":false,"modal":true},on:{"update:visible":function($event){_vm.visible=$event},"hide":function($event){return _vm.$emit('onClose')}},scopedSlots:_vm._u([(_vm.isFooterVisivle)?{key:"footer",fn:function(){return [_c('div',{staticClass:"p-d-flex p-ai-center"},[(!_vm.entityTypeMetadata.viewDialog.excludedActions?.includes('save'))?_c('Button',{attrs:{"label":_vm.$t('button.save'),"data-cy":`${_vm.entity.type}_save`,"disabled":_vm.$v.localEntity[`${_vm.entityType}${_vm._uid}`].$invalid},on:{"click":function($event){_vm.saveEntity(_vm.localEntity[`${_vm.entityType}${_vm._uid}`], _vm.entity, () =>
            _vm.$emit('onClose', true)
          )}}}):_vm._e(),_c('Button',{staticClass:"p-button-secondary p-button-outlined p-mr-1",attrs:{"label":_vm.$t('button.cancel'),"data-cy":`${_vm.entity.type}_cancel`},on:{"click":function($event){return _vm.$emit('onClose')}}}),_c('div',{staticStyle:{"flex-grow":"1"}}),_c('ErrorMesseges',{staticClass:"p-lg-7 p-md-6",staticStyle:{"padding":"0px"},attrs:{"validator":_vm.$v.localEntity[`${_vm.entityType}${_vm._uid}`],"attributes":_vm.attributes}}),_c('div',{staticStyle:{"flex-grow":"1"}}),(_vm.entity.id !== undefined && !_vm.entityTypeMetadata.viewDialog.excludedActions?.includes('delete'))?_c('Button',{staticClass:"p-button-danger p-button-outlined",attrs:{"label":_vm.$t('button.delete'),"data-cy":`${_vm.entity.type}_delete`},on:{"click":function($event){_vm.displayDeleteDialog = true}}}):_vm._e()],1)]},proxy:true}:null],null,true)},[_c('div',{staticClass:"p-dialog-inner"},[_c('ProgressBar',{style:({
        height: 'calc(2rem / var(--bfs))',
        opacity: _vm.loading ? 1 : 0,
      }),attrs:{"mode":"indeterminate"}}),(
        _vm.entityTypeMetadata.viewDialog &&
        _vm.entityTypeMetadata.viewDialog.tabs &&
        !_vm.loading
      )?_c('TabView',{staticStyle:{"position":"relative"},on:{"tab-change":_vm.tabChangeHandler}},[_vm._l((_vm.tabs),function({ title, fields, withFooter = true }){return _c('TabPanel',{key:title,attrs:{"header":_vm.$t(`tabs.${title}`),"data-footer":withFooter}},[_c('FieldsList',{staticClass:"p-grid p-m-1",attrs:{"fields":fields,"entityType":_vm.entityType,"entity":_vm.localEntity[`${_vm.entityType}${_vm._uid}`]},on:{"commit":_vm.commit,"hide":function($event){return _vm.$emit('onClose')}}})],1)}),(_vm.withPermissions)?_c('TabPanel',{attrs:{"header":_vm.$t('permissions.tabTitle'),"data-footer":true}},[_c('PermissionsTree',{attrs:{"entity":_vm.localEntity[`${_vm.entityType}${_vm._uid}`]}})],1):_vm._e(),_c('TabPanel',{attrs:{"header":"JSON","data-footer":true}},[_c('div',{staticClass:"p-grid p-m-1"},[_c('JSONField',{attrs:{"entity":{ entity: _vm.localEntity[`${_vm.entityType}${_vm._uid}`] },"options":{
              title: 'entity',
              height: '50vh',
              class: 'p-p-1',
              showLabel: false,
            }},on:{"commit":({ entity }) => _vm.commit(entity)}})],1)])],2):_vm._e()],1),_c('DeleteDialog',{attrs:{"show":_vm.displayDeleteDialog,"message":_vm.$t('message.deleteEntity'),"header":_vm.$t('message.deleteTitle')},on:{"update:show":function($event){_vm.displayDeleteDialog=$event},"onDelete":_vm.deleteEntity}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }