<template>
  <div v-if="errorMessages.length > 0">
    <InlineMessage
      v-for="(error, index) in errorMessages"
      :key="error"
      severity="error"
      :closable="false"
      style="width: 100%"
      v-show="index === currentIndex"
    >
      <div class="p-d-flex p-ai-center p-jc-between">
        <div><span class="p-mr-1" v-text="error" /></div>
        <div v-if="errorMessages.length > 1" style="min-width: 74px">
          <Button
            icon="mdi mdi-24px mdi-chevron-left"
            class="p-button-rounded p-button-outlined p-button-sm p-button-primary p-button-icon-only"
            :disabled="currentIndex === 0"
            :class="{ 'p-button-secondary': currentIndex === 0 }"
            @click="currentIndex = currentIndex - 1"
          />
          <Button
            icon="mdi mdi-24px mdi-chevron-right"
            class="p-button-rounded p-button-outlined p-button-sm p-button-primary p-button-icon-only"
            :disabled="currentIndex === errorMessages.length - 1"
            :class="{
              'p-button-secondary': currentIndex === errorMessages.length - 1,
            }"
            @click="currentIndex = currentIndex + 1"
          />
        </div>
      </div>
    </InlineMessage>
  </div>
</template>

<script>
import Vue from "vue";
import InlineMessage from "primevue/inlinemessage";
import { get } from "lodash-es";

export default Vue.extend({
  props: {
    validator: Object,
    attributes: Object,
  },
  components: {
    InlineMessage,
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  watch: {
    errorMessages(newVal) {
      if (newVal.length > 0 && this.currentIndex > newVal.length - 1) {
        this.currentIndex = newVal.length - 1;
      }
    },
  },
  computed: {
    messages() {
      return {
        required: this.$t("validations.requiredField"),
        schemaRequired: this.$t("validations.requiredField"),
        schemaMinLength: this.$t("validations.schemaMinLength"),
        schemaMaxLength: this.$t("validations.schemaMaxLength"),
        schemaMinItems: this.$t("validations.schemaMinItems"),
        schemaMaxItems: this.$t("validations.schemaMaxItems"),
        schemaMaximum: this.$t("validations.schemaMaximum"),
        schemaMinimum: this.$t("validations.schemaMinimum"),
        schemaUniqueItems: this.$t("validations.schemaUniqueItems"),
        schemaType: this.$t("validations.schemaType"),
        schemaAdditionalProperties: this.$t("validations.schemaType"),
        schemaPattern: this.$t("validations.schemaPattern"),
        durationPattern: this.$t("validations.durationPattern"),
        isReferenceValid: this.$t("validations.isReferenceValid"),
        schemaBetween: this.$t("validations.schemaBetween"),
      };
    },
    localErrors() {
      return this.flattenValidatorObjects(this.validator);
    },
    errorMessages() {
      return Array.from(
        new Set(
          this.localErrors
            .filter(({ hasError }) => hasError)
            .map((error) => this.getErrorString(error))
        )
      );
    },
  },
  methods: {
    flattenValidatorObjects(validator, fieldName) {
      // loop the validator objects
      return (
        Object.entries(validator)
          // leave those that dont have $ in their name with exception of $each
          .filter(({ 0: key }) => !key.startsWith("$") || key === "$each")
          .reduce((errors, [key, value]) => {
            // if its an object, its probably a deeply nested object
            if (typeof value === "object") {
              const nestedValidatorName =
                // Key can be "$each", a "string" or a "number" from inside "$each".
                // If "key" is "$each" or a string (from a nested object like "address.postal_code"), use the passed fieldName as its a recursive call from previous call.
                key === "$each" || !isNaN(parseInt(key))
                  ? fieldName
                  : // if fieldName is available, build it like `model.brand` from `model.$each.0.brand`.
                  fieldName
                  ? `${fieldName}.${key}`
                  : // fallback to the "key" if "fieldName" is not available
                    key;
              // recursively call the flatten again on the same error object, looking deep into it.
              return errors.concat(
                this.flattenValidatorObjects(value, nestedValidatorName)
              );
            } // else its the validated prop
            const params = Object.assign({}, validator.$params[key]);
            // delete type as it is coming for Vuelidate and may interfere with user custom attributes
            delete params.type;
            errors.push({
              fieldName: fieldName,
              validationKey: key,
              hasError: !value,
              params,
              $dirty: validator.$dirty,
              $error: validator.$error,
              $invalid: validator.$invalid,
            });
            return errors;
          }, [])
      );
    },
    getErrorString({ validationKey: key, params, fieldName }) {
      if (!fieldName) return "";
      const msg = get(this.messages, key, false);
      const _fieldName = fieldName.replace(".", "");
      if (this.attributes && this.attributes[_fieldName]) {
        params["attribute"] = this.attributes[_fieldName];
      }
      if (!msg) {
        return key;
      }
      if (typeof msg !== "string") {
        throw new TypeError(
          `Expected a string in the first argument, got ${typeof msg}`
        );
      }

      if (typeof params !== "object") {
        throw new TypeError(
          `Expected an Object/Array in the second argument, got ${typeof params}`
        );
      }
      const regx = /{(.*?)}/g;
      return msg.replace(regx, (_, key) => get(params, key, _fieldName));
    },
  },
});
</script>
