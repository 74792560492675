import { TUnionRepo } from "@netvision/lib-api-repo";
import { pickBy, mapValues, isEqual } from "lodash-es";

export const insensitify = (str: string) =>
  str
    .split("")
    .map((char) => {
      if (char.match(/[a-zа-я]/i)) {
        return `[${char.toLowerCase()}${char.toUpperCase()}]`;
      } else {
        return char;
      }
    })
    .join("");

export const coordinatesToLocation = (coordinates: [number, number]) => {
  return `${coordinates[1].toFixed(8)}, ${coordinates[0].toFixed(8)}`;
};

export const capitalize = (str: string) => {
  return typeof str === "string" && str !== ""
    ? str.replace(/^\w/, (c) => c.toLocaleUpperCase())
    : "";
};

const timers = {} as any;
export const debounce = (
  callback: Function,
  timeout: number,
  key: string = "main"
) => {
  clearTimeout(timers[key]);
  timers[key] = setTimeout(callback, timeout);
};

export const unbreakable = (str: string) => {
  const maxStrLength = 20;
  let unbreakableStr = str;
  if (unbreakableStr.length > maxStrLength) {
    unbreakableStr = unbreakableStr.slice(0, maxStrLength) + "...";
  } else {
    unbreakableStr = str.replace(" ", "\xa0").replace("-", "\u{2011}");
  }
  return unbreakableStr;
};

export const createEditObject = async (
  changedEntity: any,
  originalEntity: any,
  closeCallback: Function,
  api: TUnionRepo
) => {
  // changedEntity.type !== "EntityTypeMetadata" && delete changedEntity.id;
  changedEntity.type = originalEntity.type;
  const isNewEntity = [undefined, "", null].includes(originalEntity.id);
  const method = isNewEntity ? "createEntity" : "updateEntity";
  let options = {
    id: originalEntity.id || changedEntity.id || "",
    type: originalEntity.type,
  } as any;

  const appendOptions = { ...options };
  // have no idea how to simplify it
  if (isNewEntity) {
    options = {
      ...options,
      ...changedEntity,
    };
  } else {
    Object.keys(changedEntity).forEach((key) => {
      if (!isEqual(changedEntity[key], originalEntity[key])) {
        if (originalEntity[key] === undefined) {
          appendOptions[key] = changedEntity[key];
        } else {
          options[key] = changedEntity[key];
        }
      }
    });
  }
  if (Object.keys(appendOptions).length > 2) {
    // @ts-ignore
    "appendEntity" in api && (await api.appendEntity(appendOptions));
  }
  if (isNewEntity || Object.keys(options).length > 2) {
    api && (await api[method](options));
  }
  closeCallback();
};

export const coordinatesToPosition = (coordinates: [number, number]) => {
  return { lng: coordinates[0], lat: coordinates[1] };
};
export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
export const locationToCoordinates = (
  location: string
): [number, number] | null => {
  try {
    return location
      ? [
          Number.parseFloat(location.split(", ")[1]),
          Number.parseFloat(location.split(", ")[0]),
        ]
      : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object: any, base: any) => {
  const changes = (object: any, base: any) =>
    pickBy(
      mapValues(object, (value: any, key: any) =>
        !isEqual(value, base[key]) ? value : null
      ),
      (value: any) => value !== null
    );
  return changes(object, base);
};

export const findRealParent = (firstVueParent: any) => {
  let found = false;
  while (firstVueParent && !found) {
    if (firstVueParent.mapObject === undefined) {
      firstVueParent = firstVueParent.$parent;
    } else {
      found = true;
    }
  }
  return firstVueParent.mapObject;
};

export const findParentLayer = (firstVueParent: any) => {
  let found = false;
  while (firstVueParent && !found) {
    if (firstVueParent.layerComponent === undefined) {
      firstVueParent = firstVueParent.$parent;
    } else {
      found = true;
    }
  }
  return firstVueParent.layerComponent;
};
export const pointsToAnglesSector = (
  coordinates: Coordinate[]
): ISector | undefined => {
  const [center, pnt2, pnt3] = coordinates;
  const minimumClock = getAzimuth(pnt3, center);
  const maximumClock = getAzimuth(pnt2, center);
  return {
    type: "Sector",
    center: [center.x, center.y],
    minimumClock,
    maximumClock,
  };
};

type Coordinate = { x: number; y: number };

function getAzimuth(startPoint: Coordinate, endPoint: Coordinate) {
  let azimuth = 0;
  const angle = Math.asin(
    Math.abs(endPoint.y - startPoint.y) /
      Math.sqrt(
        Math.pow(startPoint.x - endPoint.x, 2) +
          Math.pow(startPoint.y - endPoint.y, 2)
      )
  );
  if (endPoint.y >= startPoint.y && endPoint.x >= startPoint.x) {
    azimuth = angle + Math.PI;
  } else if (endPoint.y >= startPoint.y && endPoint.x < startPoint.x) {
    azimuth = Math.PI * 2 - angle;
  } else if (endPoint.y < startPoint.y && endPoint.x < startPoint.x) {
    azimuth = angle;
  } else if (endPoint.y < startPoint.y && endPoint.x >= startPoint.x) {
    azimuth = Math.PI - angle;
  }
  return (azimuth * 180) / Math.PI;
}
