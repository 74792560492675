
import Vue from "vue";

export default Vue.extend({
  name: "ComparedField",
  props: {
    entity: Object,
    options: Object,
  },
  inject: ["entityType"],
  computed: {
    stateRows(): { field: string; header: string }[] {
      return Object.keys(this.entity[this.options.title])
        .map((key) => ({
          field: key,
          // @ts-ignore
          header: this.$t(`${this.entityType}.${key}`) as string
        }));
    }
  },
  methods: {
    valueRows(stateKey: string): { key: string; value: string | object }[] {
      return Object.values(this.entity[this.options.title][stateKey]);
    }
  }
})
