
import Vue from "vue";
import URLField from "./URLField.vue";
import { get, set } from "lodash-es";

export default Vue.extend({
  name: "ImageField",
  components: {
    URLField,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {};
  },
  computed: {
    localValue: {
      set(val: string[]) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.$emit("commit", entity);
      },
      get(): string[] {
        return get(this.entity, this.options.title) || "";
      },
    },
  },
  methods: {
    decodeURIComponent,
  },
});
