var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('MapTalksMap',{attrs:{"mapView":_vm.currentMapView,"options":{
      width: '100vw',
      height: '100vh',
    }},on:{"update:mapView":function($event){_vm.currentMapView=$event},"update:map-view":function($event){_vm.currentMapView=$event}}},[_c('TLayer',{attrs:{"layerName":`${_vm._uid}_editable_layer`}},[(_vm.drawMode !== null)?_c('TDrawTool',{attrs:{"mode":_vm.drawMode,"options":_vm.drawOptions},on:{"endDraw":_vm.endDraw}}):_vm._e(),_vm._l((_vm.allFeatures),function(feature,index){return _c('div',{key:`${feature.id || index}_feature`},[(feature.type === 'Feature')?_c('TPolygon',{attrs:{"feature":feature,"options":{
            editable: !feature.freezed,
            symbol: {
              lineColor: 'rgba(60, 114, 255, 1)',
              lineWidth: 6,
              polygonFill: 'rgba(41, 66, 131, 0.2)',
              lineJoin: 'round',
              shadowColor: '#3C72FF',
              shadowBlur: 10,
            },
          },"editing":_vm.currentEditing === `${feature.id || index}_feature` ||
            _vm.mode === 'single'},on:{"click":function($event){return _vm.setAsCurrent(feature, index)},"updateShape":(value) => _vm.updateShape(index, value)}}):_vm._e(),(
            feature.type === 'Feature' &&
            feature.properties &&
            feature.properties.label !== undefined
          )?_c('TMarker',{attrs:{"draggable":!feature.freezed,"location":_vm.isArray(feature.geometry.coordinates[0])
              ? feature.geometry.coordinates[0]
              : feature.geometry.coordinates,"animationDuration":2,"editing":_vm.currentEditing === `${feature.id || index}_feature` ||
            _vm.mode === 'single'},on:{"dragend":(position) => _vm.updateLocation(position, feature, index)}},[_c('div',{staticClass:"construction-label",style:(_vm.currentEditing === `${feature.id || index}_feature`
                ? {
                    border: 'solid 1px var(--primary-color-dark)',
                  }
                : {}),on:{"click":function($event){$event.stopPropagation();return _vm.setAsCurrent(feature, index)},"dblclick":function($event){_vm.labelEditing = !feature.freezed
                ? `${feature.id || index}_label`
                : ''}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.labelEditing !== `${feature.id || index}_label`),expression:"labelEditing !== `${feature.id || index}_label`"}],staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},attrs:{"role":"text","title":feature.properties.label},domProps:{"textContent":_vm._s(
                feature.properties.label.length > _vm.maxChars
                  ? feature.properties.label.slice(0, _vm.maxChars) + '...'
                  : feature.properties.label
              )}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(feature.properties.label),expression:"feature.properties.label"}],ref:`${feature.id || index}_label`,refInFor:true,style:({
                position: 'absolute',
                cursor:
                  _vm.labelEditing === `${feature.id || index}_label`
                    ? 'text'
                    : 'default',
                pointerEvents:
                  _vm.labelEditing === `${feature.id || index}_label`
                    ? 'all'
                    : 'none',
                opacity:
                  _vm.labelEditing === `${feature.id || index}_label` ? 1 : 0,
              }),attrs:{"type":"text"},domProps:{"value":(feature.properties.label)},on:{"blur":function($event){_vm.updateShape(index, feature);
                _vm.labelEditing = '';},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.updateShape(index, feature);
                _vm.labelEditing = '';},"input":function($event){if($event.target.composing)return;_vm.$set(feature.properties, "label", $event.target.value)}}})])]):_vm._e(),(
            feature.type === 'Feature' &&
            feature.geometry &&
            feature.geometry.type === 'Point' &&
            feature.properties === undefined
          )?_c('TMarker',{attrs:{"location":_vm.isArray(feature.geometry.coordinates[0])
              ? feature.geometry.coordinates[0]
              : feature.geometry.coordinates,"animationDuration":2}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(feature.title),expression:"feature.title",modifiers:{"right":true}}],staticClass:"room-marker room-marker__active",staticStyle:{"opacity":"0.5"}},[_c('div',{staticClass:"room-marker__camera"})])]):_vm._e(),(feature.type === 'Sector')?_c('TMarker',{attrs:{"location":feature.center,"animationDuration":2}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(feature.title),expression:"feature.title",modifiers:{"right":true}}],staticClass:"room-marker room-marker__active"},[_c('div',{staticClass:"room-marker__camera"})])]):_vm._e(),(feature.type === 'Sector')?_c('TSector',{attrs:{"center":feature.center,"zIndex":3,"startAngle":feature.minimumClock,"endAngle":feature.maximumClock,"options":{
            editable: !feature.freezed,
            symbol: {
              lineColor: 'rgba(60, 114, 255, 1)',
              lineWidth: 6,
              polygonFill: 'rgba(41, 66, 131, 0.2)',
              lineJoin: 'round',
              shadowColor: '#3C72FF',
              shadowBlur: 10,
            },
          },"editing":_vm.currentEditing === `${feature.id || index}_feature` ||
            _vm.mode === 'single'},on:{"click":function($event){return _vm.setAsCurrent(feature, index)},"updateShape":(value) => _vm.updateShape(index, value)}}):_vm._e()],1)})],2)],1),_c('div',{staticStyle:{"position":"absolute","top":"50%","right":"2rem"}},[_c('div',{staticClass:"p-d-flex p-flex-column",staticStyle:{"position":"relative","transform":"translateY(-50%)"}},[_vm._l((_vm.tools),function(tool){return _c('div',{key:tool},[(_vm.drawButtons[tool])?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(_vm.drawButtons[tool].tooltip),expression:"drawButtons[tool].tooltip",modifiers:{"left":true}}],staticClass:"p-mb-1",class:[
            _vm.drawMode === _vm.drawButtons[tool].drawMode
              ? ''
              : 'p-button-outlined',
          ],attrs:{"icon":`mdi mdi-18px ${_vm.drawButtons[tool].icon}`,"disabled":_vm.drawMode !== null ||
            (_vm.mode === 'single' && _vm.editableGeometries.length !== 0)},on:{"click":function($event){_vm.drawMode = _vm.drawButtons[tool].drawMode;
            _vm.drawTool = true;}}}):_vm._e()],1)}),_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(_vm.$t('button.deleteAll')),expression:"$t('button.deleteAll')",modifiers:{"left":true}}],staticClass:"p-button-danger p-button-outlined",attrs:{"disabled":_vm.editableGeometries.length === 0,"icon":"mdi mdi-18px mdi-vector-polyline-remove"},on:{"click":function($event){_vm.displayDeleteDialog = true}}})],2),_c('DeleteDialog',{attrs:{"show":_vm.displayDeleteDialog,"message":_vm.$t('message.deleteAll'),"header":_vm.$t('message.deleteTitle')},on:{"update:show":function($event){_vm.displayDeleteDialog=$event},"onDelete":function($event){return _vm.$emit('update:editableGeometries', [])}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }