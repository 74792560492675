
import Mixins from "../../../mixins";
import { mapStateTyped } from "../../../store";
import Message from "primevue/message";
// eslint-disable-next-line no-unused-vars 
import { PublishPayload } from "@netvision/lib-types-frontend";
import { name } from '../../../../package.json'

export default Mixins.extend({
  name: "TableStreams",
  components: {
    Message
  },
  props: {
    entity: Object,
    options: Object,
    isReady: {
      type: Boolean,
      required: true
    },
    widgetName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapStateTyped(["spaParent", "api"]),
  },
  watch: {
    eventBusID(value: string) {
      // @ts-ignore
      this.spaParent.props.eventBus.subscribe(value, 'openDetails', this.mountDetails)
      // @ts-ignore
      this.spaParent.props.eventBus.subscribe(value, 'deleteEntities', this.deleteStreams)
    }
  },
  mounted() {
    this.areas = this.spaParent.areas
    this.mount = this.spaParent.mountChildren
    this.mountWidget()
  },
  data() {
    return {
      unmount: () => {},
      eventBusID: '',
      isDetailed: false,
      areas: [] as IArea[],
      mount: Function as any
    };
  },
  methods: {
    async mountWidget() {
      const ref = this.$refs["StreamsTable"];
      const mountArea = this.areas.find(({ name }) => name === this.options.title);
      if (!mountArea) return false;
      const data = await this.fetchStreams();
      if (!data) return false;
      this.eventBusID = this.spaParent.props.eventBus.addUniqueSubscriberMap();
      const unmount = this.mount(
        ref,
        mountArea.children.map((child) => {
          return {
            ...child,
            props: {
              ...child.props,
              ...data,
              eventBusID: this.eventBusID
            },
          };
        })
      )
      this.$emit('unmount', unmount);
    },
    mountDetails(payload: PublishPayload<Record<string, any>>) {
      const mountArea = this.areas.find(({ name }) => name === 'details');
      if (!mountArea) return false;
      this.isDetailed = true;
      this.$nextTick(() => {
        const ref = this.$refs["details"];
        this.unmount = this.mount(
          ref,
          mountArea.children.map((child) => {
            if (child.props?.provide?.length) {
              child.props.provide.forEach((field: string) => {
                payload.data[field] = this.entity.id
              })
            }
            return {
              ...child,
              props: {
                ...child.props,
                entity: payload.data,
                entityType: 'Stream',
                onClose: (isRefreshRequired?: boolean) => {
                  this.isDetailed = false;
                  if (isRefreshRequired) {
                    this.fetchStreams()
                  }
                }
              },
            };
          })
        )
      })
    },
    async fetchStreams() {
      this.$emit('setReadyState', false)
      try {
        const schema = this.options.schema
        const { results } = await this.api.getEntitiesList({
          limiter: { type: "Stream", orderBy: "title" },
          filter: {
            q: [
              {
                key: "cameraId",
                value: this.entity.id,
                operator: "==",
              },
            ],
          },
        })
        const resWithLazyAttrs = await Promise.all(
          results.map(async (el: any) => {
            const res = await this.api.getEntitiesWithGlobalBatch(
              { id: el.id, type: el.type },
              ["snapshotStreamUrl", "snapshotSubStreamUrl"],
              "stream-preview"
            );
            return { ...el, ...res };
          })
        );
        const data = await Promise.all(
          resWithLazyAttrs.map(async (el: any) => {
            const streamPlayer = await this.fetchFile(
              el.snapshotStreamUrl || el.snapshotSubStreamUrl
            );
            const { entity } = el.streamerId
              ? await this.api.getEntity({
                  id: el.streamerId,
                  type: "Streamer",
                })
              : { entity: { title: "" } };
            return { ...el, streamPlayer, streamerTitle: entity?.title };
          })
        );
        this.$emit('setReadyState', true)
        this.eventBusID && this.spaParent.props.eventBus.notify(this.eventBusID, 'data:changed', {
          publisher: name,
          occurrenceTime: Date.now(),
          data
        })
        return { schema, data };
      } catch(error) {
        this.$emit('setReadyState', true)
        this.errorToast(error, this.$t("errors.gettingStreams"));
        return null
      }
    },
    async fetchFile(fileUrl?: string) {
      try {
        if (!fileUrl) return "error";
        return fetch(fileUrl, {
          method: "GET",
          headers: await this.api.getHeaders(),
        })
          .then((res) => {
            if (res.status === 404) {
              return "notFound";
            }
            const cType = res.headers.get("content-type");
            if (cType && cType.startsWith("video/")) {
              return res.blob() as unknown as Blob;
            }
            return "error";
          })
          .then((res) => {
            return res instanceof Blob ? URL.createObjectURL(res) : res;
          })
          .catch((error) => {
            console.error(error);
            return "notFound";
          });
      } catch (error) {
        return "error";
      }
    },
    async deleteStreams(payload: PublishPayload<Record<string, any>[]>) {
      this.$emit("setReadyState", false)
      await Promise.all(payload.data.map(
        async ({ id, type }) => await this.api.deleteEntity({ id, type })
      ));
      const streams = await this.fetchStreams()
      this.eventBusID && this.spaParent.props.eventBus.notify(this.eventBusID, 'data:changed', {
        publisher: name,
        occurrenceTime: Date.now(),
        data: streams?.data || []
      })
      this.$emit("setReadyState", true)
    }
  },
  beforeDestroy() {
    if (this.eventBusID) {
      this.spaParent.props.eventBus.unsubscribe(this.eventBusID, 'openDetails')
      this.spaParent.props.eventBus.unsubscribe(this.eventBusID, 'deleteEntities')
    }
  }
})
