import { mapState } from "vuex";
import { GlobalEventBus, GlobalObserver } from "@netvision/lib-types-frontend";

const initialState = () => ({
  loading: false,
  isDarkTheme: true,
  widgetUUID: "",
  strictRegEx: /[&?/#<>"'=;()]/,
  required: {} as Record<string, string[]>,
  existingReferenceIds: ["", undefined] as string[],
  maxChars: 25,
  api: {} as any,
  spaParent: {} as ISpaParent<GlobalEventBus, GlobalObserver>,
});

type State = ReturnType<typeof initialState>;

export default {
  state: initialState,
  mutations: {
    setValue(state: State, [key, value]: [string, any]) {
      // @ts-ignore
      state[key] = value;
    },
    reset(state: State) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        // @ts-ignore
        state[key] = s[key];
      });
    },
  },
};

export const mapStateTyped = <
  T extends keyof State,
  G extends { [Key in T]: () => State[Key] }
>(
  keys: T[]
): G => {
  return { ...mapState(keys) } as G;
};
