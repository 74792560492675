
import Vue from "vue";
import { get, set } from "lodash-es";
import { mapStateTyped } from "@/store";

export default Vue.extend({
  name: "GroupsField",
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      groups: [],
    };
  },
  computed: {
    ...mapStateTyped(["api"]),
    localValue: {
      set(val: string[]) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.$emit("commit", entity);
      },
      get(): string[] {
        return get(this.entity, this.options.title) || [];
      },
    },
  },
  methods: {
    async fetchGroupsNames() {
      const groups = await Promise.allSettled(
        this.entity[this.options.title].map(async (id: string) => {
          const { entity } = await this.api.getEntity({ id, type: "Groups" });
          return entity;
        })
      );
      //@ts-ignore
      this.groups = groups.map(({ value }) => value.title);
    },
  },
  mounted() {
    if (this.entity[this.options.title]?.length) {
      this.fetchGroupsNames();
    }
  },
});
