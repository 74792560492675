
import Mixins from "../../mixins";
import { get } from "lodash-es";

export default Mixins.extend({
  name: "FieldLabel",
  props: {
    options: Object,
    fieldClass: { type: String, default: "" },
  },
  data() {
    return {};
  },
  computed: {
    label() {
      // @ts-ignore
      const entityType = this.entityType;
      return this.te(
        [
          `${entityType}.${this.options.title.replace(".", "")}`,
          `${entityType}.${this.options.title}`,
          `${entityType}.attrs.${this.options.title.replace(".", "")}`,
        ],
        this.options.title
      );
    },
  },
  methods: {
    get,
  },
  inject: ["$v", "uid", "entityType"],
});
