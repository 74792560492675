
import Vue from "vue";
//@ts-ignore
import { Polygon, animation, GeoJSON } from "maptalks";
import { findParentLayer } from "@/utils";

export default Vue.extend({
  name: "TPolygon",
  props: {
    points: Array,
    feature: Object,
    options: Object,
    fadingAnimation: Boolean,
    editing: Boolean,
    visible: { type: Boolean, required: false, default: undefined },
  },
  data() {
    return {
      polygon: {} as any,
      forward: true,
      player: {} as any,
    };
  },
  watch: {
    options: {
      handler(val) {
        if (val.symbol && this.polygon.setSymbol) {
          this.polygon.setSymbol(val.symbol);
        }
      },
      deep: true,
    },
    editing(val) {
      if (val) {
        this.polygon.startEdit();
      } else {
        this.polygon.endEdit();
      }
    },
    visible(val) {
      if (val) {
        if (this.fadingAnimation) {
          this.player.play();
        }
      } else {
        if (this.fadingAnimation) {
          this.player.pause();
        }
      }
    },
  },
  mounted() {
    const layer = findParentLayer(this);
    if (this.points) {
      this.polygon = new Polygon([this.points], this.options);
    }
    if (this.feature) {
      this.polygon = GeoJSON.toGeometry(this.feature, (geom: any) => {
        geom.setSymbol(this.options.symbol);
      });
    }
    this.polygon?.addTo?.(layer);
    this.polygon?.on?.("click", () => {
      this.$emit("click");
    });
    if (this.options && this.options.editable) {
      this.polygon?.on?.("editrecord", ({ target }: any) => {
        const geoJSON = target.toGeoJSON();
        this.$emit("updateShape", geoJSON);
      });
      if (this.editing) {
        this.polygon?.startEdit();
      }
    }
    if (this.fadingAnimation) {
      this.player = animation.Animation.animate(
        { symbol: { polygonOpacity: 0.5 } },
        {
          duration: 500,
          easing: "out",
          repeat: true,
        },
        (frame: any) => {
          if (frame.state.playState === "running") {
            if (frame.styles.symbol.polygonOpacity === 0) {
              this.forward = !this.forward;
            }
            frame.styles.symbol.polygonOpacity = this.forward
              ? frame.styles.symbol.polygonOpacity + 0.1
              : 0.6 - frame.styles.symbol.polygonOpacity;
            this.polygon.updateSymbol(frame.styles.symbol);
          }
        }
      );
      if (this.visible) {
        this.player.play();
      }
    }
  },
  destroyed() {
    const layer = findParentLayer(this);
    layer.removeGeometry(this.polygon);
  },
});
