var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('FieldLabel',{attrs:{"options":_vm.options}},[(!_vm.localValue)?_c('AutocompleteField',{staticClass:"p-mt-1",attrs:{"value":_vm.searchField,"label":_vm.$t('form.choose'),"entityType":_vm.options.entityType,"data-cy":`${_vm.entity.type}_${_vm.options.title}`,"searchField":_vm.options.labelField,"editable":!_vm.isDisabled},on:{"onSelect":({ value }) => {
        _vm.nameFetched = true;
        _vm.name = value[_vm.options.labelField];
        _vm.localValue = value.id;
      },"unselect":function($event){_vm.localValue = ''}}}):_c('div',{staticClass:"p-d-flex p-mt-1 p-px-1 p-ai-center",staticStyle:{"height":"40px"}},[(_vm.nameFetched)?_c('span',{class:[{ 'p-disabled': _vm.isDisabled }, 'p-mr-2 title-label']},[_vm._v(_vm._s(_vm.name))]):_vm._e(),_c('Button',{class:[
        { 'p-disabled': _vm.isDisabled },
        'p-button-rounded p-button-text',
      ],attrs:{"type":"button","icon":"mdi mdi-18px mdi-close","data-cy":`${_vm.entity.type}_${_vm.options.title}_close`,"disabled":_vm.isDisabled},on:{"click":function($event){_vm.localValue = ''}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }