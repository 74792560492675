
import Vue from "vue";
import componentMap from "./fieldsMap";
import InputField from "./fields/InputField.vue";

export default Vue.extend({
  name: "FieldsList",
  props: {
    fields: Array,
    entity: Object,
    entityType: String,
    context: Object,
  },
  data() {
    return {
      InputField,
      componentMap,
    };
  },
});
