
import Vue from "vue";
import InputField from "./fields/InputField.vue";
import JSONField from "./fields/JSONField.vue";
import PermissionsTree from "./PermissionsTree.vue";
import ErrorMesseges from "./common/ErrorMesseges.vue";
import { createEditObject } from "@/utils";
import { required } from "vuelidate/lib/validators";
import { intersection, set } from "lodash-es";
import { mapStateTyped } from "@/store";

const STRINGS_REGEX = /[&?/#<>"'=;()]/;
const DURATION_REGEX =
  /^((\d+y\s*)?(\s*)?(\d+M\s*)?(\s*)?(\d+w\s*)?(\s*)?(\d+d\s*)?(\s*)?(\d+h\s*)?(\s*)?(\d+m\s*)?(\s*)?(\d+s\s*)?)$/;

export default Vue.extend({
  name: "AdminDialog",
  props: {
    entity: Object,
    loading: Boolean,
    entityType: String,
    withPermissions: Boolean,
    entityTypeMetadata: Object,
  },
  components: {
    PermissionsTree,
    JSONField,
    ErrorMesseges,
  },
  data() {
    return {
      visible: true,
      InputField,
      displayDeleteDialog: false,
      changeMap: new Map() as Map<string, string>,
      localEntity: {
        [`${this.entityType}${this._uid}`]: JSON.parse(
          JSON.stringify(this.entity)
        ),
      },
      isValidJSON: true,
      isFooterVisivle: true,
    };
  },
  schema: [
    {
      mountPoint: "localEntity",
      schema: {},
    },
  ],
  validations(): any {
    const validators = {} as Record<string, any>;
    if (this.entityTypeMetadata.viewDialog?.tabs) {
      const fields = this.entityTypeMetadata.viewDialog.tabs.reduce(
        (acc: any[], { fields }: any) => {
          return [...acc, ...fields];
        },
        []
      );
      fields.forEach((option: any) => {
        if (["string", "text"].includes(option.type)) {
          set(validators, option.title, {
            schemaPattern: (val: string) => !val || !STRINGS_REGEX.test(val),
          });
        }
        if (option.type === "duration") {
          set(validators, option.title, {
            durationPattern: (val: string) => {
              return (
                typeof val === "number" || !val || DURATION_REGEX.test(val)
              );
            },
          });
        }
        if (["multiReference", "reference"].includes(option.type)) {
          set(validators, option.title, {
            ...validators[option.title],
            isReferenceValid: (val: any) => {
              switch (true) {
                case typeof val === "string":
                  return this.existingReferenceIds.includes(val);
                case Array.isArray(val):
                  return (
                    intersection(this.existingReferenceIds, val).length ===
                    val.length
                  );
                default:
                  return true;
              }
            },
          });
        }
      });
    }
    const requiredFields: string[] =
      this.entityTypeMetadata.schemaCreate &&
      this.entityTypeMetadata.schemaCreate.required;
    if (requiredFields) {
      requiredFields.forEach((key) => {
        set(validators, key, { ...validators[key], required });
      });
    }
    return { localEntity: { [`${this.entityType}${this._uid}`]: validators } };
  },
  provide(): any {
    return {
      $v: this.$v,
      uid: this._uid,
      isNewEntity: !this.entity.id,
      entityType: this.entityType,
    };
  },
  computed: {
    ...mapStateTyped(["existingReferenceIds", "api"]),
    attributes(): any {
      const attributes = {} as Record<string, string>;
      const locale =
        this.entityTypeMetadata.locales?.ru ||
        this.entityTypeMetadata.localeRu?.attrs;
      if (locale) {
        Object.entries(locale as Record<string, string>).forEach(
          ([key, value]) => {
            attributes[key] = value;
          }
        );
      }
      return attributes;
    },
    tabs(): any {
      return this.entityTypeMetadata.viewDialog.tabs.filter(
        (e: { forbidenOnCreate?: boolean }) =>
          this.entity.id || !e.forbidenOnCreate
      );
    },
    dialogHeader(): string {
      return (
        this.entity.title ||
        this.entity[this.entityTypeMetadata.viewDialog?.headerKey] ||
        this.entityTypeMetadata.localeRu?.typeTitle ||
        this.entityTypeMetadata.locales?.ru?.common?.typeTitle ||
        this.entityTypeMetadata.locales?.ru?.title || ""
      )
    },
  },
  methods: {
    commit(entity: any) {
      this.localEntity = { [`${this.entityType}${this._uid}`]: { ...entity } };
    },
    tabChangeHandler({ tab }: { tab: any }) {
      this.isFooterVisivle = tab.$attrs["data-footer"];
    },
    async deleteEntity() {
      try {
        await this.api.deleteEntity({
          type: this.entityType,
          id: this.entity.id,
        });
        this.$emit("onClose", true);
      } catch (error) {
        this.errorToast(error, this.$t("errors.deletingImpossible"));
      }
    },
    errorToast(error: any, message?: any) {
      console.error(error);
      this.$toast.add({
        severity: "error",
        summary: this.$t("message.error"),
        detail: message || error.message,
        life: 5000,
      });
    },
    saveEntity(
      changedEntity: any,
      originalEntity: any,
      closeCallback: Function
    ) {
      createEditObject(
        changedEntity,
        originalEntity,
        closeCallback,
        this.api
      ).catch((error) => {
        this.errorToast(error, this.$t("errors.save"));
      });
    },
  },
  async mounted() {
    const schema = {} as any;
    const { schemaCreate } = this.entityTypeMetadata;
    if (schemaCreate) {
      schema.mountPoint = `localEntity.${this.entityType}${this._uid}`;
      for (const [key, property] of Object.entries(schemaCreate.properties) as [
        string,
        any
      ]) {
        if (property.$ref) {
          try {
            const {
              entity: { schemaCreate: schemaCreateInternal },
            } = await this.api.getEntity({
              id: `EntityTypeMetadata:${property.$ref}`,
              type: "EntityTypeMetadata",
            });
            schemaCreate.properties[key] = schemaCreateInternal || {};
          } catch (error) {
            console.error(error);
          }
        }
      }
      delete schemaCreate.additionalProperties;
      delete schemaCreate.type;
      schema.schema = schemaCreate;
    }
    Reflect.ownKeys(schema).length && this.$schema.push(schema);
  },
  beforeDestroy() {},
});
