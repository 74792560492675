import "./set-public-path";
import Vue from "vue";
import singleSpaVue from "single-spa-vue";
import Vuex from "vuex";
import Store from "./store";
Vue.use(Vuex);

//@ts-ignore
import VueVuelidateJsonschema from "vue-vuelidate-jsonschema";
import Vuelidate from "vuelidate";

Vue.use(VueVuelidateJsonschema);
Vue.use(Vuelidate);

import Toast from "primevue/toast";
Vue.component("Toast", Toast);
import ToastService from "primevue/toastservice";
Vue.use(ToastService);
import Tooltip from "primevue/tooltip";
Vue.directive("tooltip", Tooltip);
import InputText from "primevue/inputtext";
Vue.component("InputText", InputText);
import Button from "primevue/button";
Vue.component("Button", Button);
import Dialog from "primevue/dialog";
Vue.component("Dialog", Dialog);
import TabView from "primevue/tabview";
Vue.component("TabView", TabView);
import TabPanel from "primevue/tabpanel";
Vue.component("TabPanel", TabPanel);
import Textarea from "primevue/textarea";
Vue.component("Textarea", Textarea);
import ProgressBar from "primevue/progressbar";
Vue.component("ProgressBar", ProgressBar);
import FieldLabel from "@/components/common/FieldLabel.vue";
Vue.component("FieldLabel", FieldLabel);
import DeleteDialog from "@/components/common/DeleteDialog.vue";
Vue.component("DeleteDialog", DeleteDialog);
import FieldsList from "@/components/FieldsList.vue";
Vue.component("FieldsList", FieldsList);

//@ts-ignore
import VJsoneditor from "v-jsoneditor/src/index";
Vue.component("VJsoneditor", VJsoneditor);

import VueI18n from "vue-i18n";
import { RUSSIAN_TRANSLATIONS } from "./translations/ru";

import App from "./App.vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
Vue.use(VueI18n);

Vue.config.productionTip = false;

const routes = [] as any;
const router = new VueRouter({
  base: __dirname,
  mode: "history",
  routes,
});
const locale = "ru";
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
};
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
});
const store = new Vuex.Store(Store);

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    i18n,
    store,
    render: (h: any) => h(App),
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement("div");
    props.domElement.appendChild(el);
    props.domElement = el;
  }
  return vueLifecycles.mount(props);
};
export const unmount = vueLifecycles.unmount;
