
import Vue from "vue";
import { getWidgetMap } from "./mapStore";
import { mapStateTyped } from "@/store";
//@ts-ignore
import { VectorLayer } from "maptalks";

export default Vue.extend({
  name: "TLayer",
  props: {
    layerName: String,
    options: Object,
    visible: Boolean,
  },
  data() {
    return {
      layerComponent: {} as any,
    };
  },
  computed: {
    ...mapStateTyped(["widgetUUID"]),
  },
  beforeMount() {
    const parentMap = getWidgetMap(this.widgetUUID);

    this.layerComponent = new VectorLayer(this.layerName, [], {
      ...this.options,
      zIndex: 10,
    }).addTo(parentMap);
  },
  beforeDestroy() {
    this.layerComponent.remove();
  },
});
