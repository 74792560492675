
import Vue from "vue";
import { mapStateTyped } from "@/store";
import AutocompleteField from "../common/AutocompleteField.vue";
import SelectButton from "primevue/selectbutton";

export default Vue.extend({
  name: "PermissionsSubjectsField",
  props: {
    entity: Object,
    options: Object,
  },
  components: {
    AutocompleteField,
    SelectButton,
  },
  async mounted() {
    const { properties }: { properties: Record<string, any> } = this.options;
    this.fields = Object.entries(properties).reduce<Record<string, any>>(
      (acc, [key, props]) => {
        acc[key] = {
          props,
          selectedOption: null,
        };
        return acc;
      },
      {}
    );
    const selectedSubject = Object.entries(properties).find(
      ([key]) => this.entity[key]
    );
    if (selectedSubject) {
      const entity = await this.fetchEntityTitle(
        this.entity[selectedSubject[0]],
        selectedSubject[1].entityType
      );
      this.selectedField = selectedSubject[0];
      this.setFieldValue({ value: entity });
    }
  },
  computed: {
    ...mapStateTyped(["api"]),
    fieldsKeys(): { value: string; label: any }[] {
      return [
        ...Object.keys(this.fields).map((key) => ({
          value: key,
          label: this.$t(`${this.entity.type}.attrs.subjects.${key}`),
        })),
        { value: "all", label: this.$t("subjects.all") },
      ];
    },
    autocompleteProps(): any {
      return {
        value: this.searchField,
        label: this.$t("form.choose"),
        entityType: this.fields[this.selectedField]?.props.entityType,
        searchField: this.fields[this.selectedField]?.props.searchField,
        editable: this.options.editable === undefined || this.options.editable,
      };
    },
    selectedOption(): any {
      return this.fields[this.selectedField].selectedOption;
    },
  },
  data() {
    return {
      fields: {} as Record<string, any>,
      selectedField: "all",
      searchField: "",
    };
  },
  watch: {
    selectedField(value) {
      if (value === "all") {
        this.setFieldValue({ value: null });
      }
    },
  },
  methods: {
    async fetchEntityTitle(id: string, type: string) {
      try {
        const { entity } = await this.api.getEntity({ id, type });
        return entity;
      } catch (error) {
        console.error(error);
      }
    },
    setFieldValue({ value }: any) {
      this.fields = Object.entries(this.fields).reduce<Record<string, any>>(
        (acc, [key, data]) => {
          acc[key] = {
            ...data,
            selectedOption:
              key !== this.selectedField || !value
                ? null
                : {
                    id: value.id,
                    name: value[data.props.searchField || "title"],
                  },
          };
          return acc;
        },
        {}
      );
      this.updateFieldValue();
    },
    updateFieldValue() {
      const entity = { ...this.entity };
      Object.entries(this.fields).forEach(([key, { selectedOption }]) => {
        entity[key] = !selectedOption ? null : selectedOption.id;
      });
      this.$emit("commit", entity);
    },
  },
});
