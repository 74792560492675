
import Mixins from "../../../mixins";
import { mapStateTyped } from "../../../store";

export default Mixins.extend({
  name: "PasswordWidget",
  props: {
    entity: Object,
    options: Object,
    isReady: {
      type: Boolean,
      required: true,
    },
    widgetName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapStateTyped(["spaParent", "api"]),
  },
  mounted() {
    this.areas = this.spaParent.areas;
    this.mount = this.spaParent.mountChildren;
    this.mountWidget();
  },
  data() {
    return {
      unmount: () => {},
      isDetailed: false,
      areas: [] as IArea[],
      mount: Function as any,
    };
  },
  methods: {
    mountWidget() {
      const ref = this.$refs["passwordRef"];
      const mountArea = this.areas.find(
        ({ name }) => name === this.options.title
      );
      if (!mountArea) return false;
      const unmount = this.mount(
        ref,
        mountArea.children.map((child) => {
          return {
            ...child,
            props: {
              ...child.props,
              userId: this.entity.id,
              userEnabled: this.entity.enabled,
            },
          };
        })
      );
      this.$emit("setReadyState", true);
      this.$emit("unmount", unmount);
    },
  },
});
