var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AimMarker',{attrs:{"entity":_vm.entity,"options":_vm.options,"context":_vm.context},on:{"commit":(entity) => _vm.$emit('commit', entity)},scopedSlots:_vm._u([{key:"new",fn:function(){return [_c('div',{staticClass:"p-d-flex p-ai-center room-label",staticStyle:{"pointer-events":"auto","cursor":"pointer"}},[_c('i',{staticClass:"mdi mdi-24px p-mr-2",class:_vm.entity.icon.iconClass}),_c('span',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},domProps:{"textContent":_vm._s(
          _vm.entity.title && _vm.entity.title.length > _vm.maxChars
            ? _vm.entity.title.slice(0, _vm.maxChars) + '...'
            : _vm.entity.title
        )}})])]},proxy:true},{key:"origin",fn:function(){return [_c('div',{staticClass:"p-d-flex p-ai-center room-label",staticStyle:{"pointer-events":"auto","cursor":"pointer","opacity":"50%"}},[_c('i',{staticClass:"mdi mdi-24px p-mr-2",class:_vm.entity.icon.iconClass}),_c('span',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},domProps:{"textContent":_vm._s(
          _vm.entity.title && _vm.entity.title.length > _vm.maxChars
            ? _vm.entity.title.slice(0, _vm.maxChars) + '...'
            : _vm.entity.title
        )}})])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }