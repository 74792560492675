var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"p-m-0 p-d-flex",staticStyle:{"width":"80vw","padding":"0 !important","height":"calc(85vh - 300px)"}},[_vm._l((_vm.columns),function(column){return _c('div',{key:column.name,staticClass:"p-px-1 p-py-0",style:(column.style)},[_c('div',{staticClass:"column-list p-mr-1"},[_c('div',{staticClass:"p-d-flex p-jc-center p-py-1"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t(`${_vm.entity.type}.columns.${column.name}`))}})]),(column.list && column.list.length > 0)?_c('div',{staticStyle:{"flex-grow":"1","overflow":"auto"}},[_c('draggable',{staticClass:"column-content p-mt-1",attrs:{"disabled":!(column.draggable && _vm.nowEditingColumn.includes(column.name)),"sort":column.sortable,"tag":"div"},on:{"end":(event) => column.onSort && column.onSort(event)},model:{value:(column.list),callback:function ($$v) {_vm.$set(column, "list", $$v)},expression:"column.list"}},_vm._l((column.list),function(element){return _c('div',{key:column.getLabel(element),staticClass:"column-row p-mb-2 p-px-2",class:{ 'column-row_active': column.isActive(element) },attrs:{"data-id":element.id,"data-type":element.areaType},on:{"click":() => {
                column.onClick(element);
                _vm.originalObject = { type: element.type };
                if (element.id) {
                  _vm.originalObject = _vm.clone(element);
                }
                _vm.setEditingEntity(column, element);
              }}},[(
                column.draggable && _vm.nowEditingColumn.includes(column.name)
              )?_c('i',{staticClass:"mdi mdi-18px mdi-drag p-mr-1"}):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(column.getLabel(element))}}),_c('div',{staticStyle:{"flex-grow":"1"}}),(!_vm.nowEditingColumn.includes(column.name))?_c('i',{staticClass:"mdi mdi-18px mdi-menu-right"}):_vm._e(),(_vm.nowEditingColumn.includes(column.name))?_c('i',{staticClass:"mdi mdi-18px mdi-delete",on:{"click":function($event){$event.preventDefault();_vm.deleteElement = element;
                _vm.currentDeleteFunc = column.onDelete;
                _vm.displayDeleteDialog = true;}}}):_vm._e()])}),0)],1):_c('div',{staticStyle:{"flex-grow":"1"}}),_c('div',{staticClass:"p-d-flex p-jc-between p-m-1"},[_c('Button',{staticClass:"p-button-outlined p-mr-1",staticStyle:{"width":"100%"},attrs:{"label":_vm.$t('button.add'),"data-cy":`${_vm.entity.type}_${_vm.options.title}_add`,"disabled":column.addButtonDisabled()},on:{"click":function($event){_vm.newEntity = {};
            _vm.cancelEditingEntity();
            _vm.newEntityColumn.push(column.name);}}}),(!_vm.nowEditingColumn.includes(column.name))?_c('Button',{staticClass:"p-button-secondary p-button-outlined",attrs:{"disabled":column.addButtonDisabled(),"data-cy":`${_vm.entity.type}_${_vm.options.title}_edit`,"icon":"mdi mdi-18px mdi-pencil"},on:{"click":function($event){return _vm.nowEditingColumn.push(column.name)}}}):_vm._e(),(_vm.nowEditingColumn.includes(column.name))?_c('Button',{staticClass:"p-button-secondary p-button-outlined",attrs:{"icon":"mdi mdi-18px mdi-close","data-cy":`${_vm.entity.type}_${_vm.options.title}_close`},on:{"click":function($event){_vm.nowEditingColumn = _vm.nowEditingColumn.filter(
              (e) => e !== column.name
            )}}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.newEntityColumn.includes(column.name)),expression:"newEntityColumn.includes(column.name)"}],staticClass:"column-dialog"},[_c('div',{staticClass:"column-dialog__mask"}),_c('div',{staticClass:"column-dialog__form p-raised"},[_c('FieldsList',{staticClass:"p-grid p-m-1",attrs:{"fields":column.createSchema,"entity":_vm.newEntity},on:{"commit":(entity) => {
                _vm.newEntity = entity;
              }}}),_c('div',{staticClass:"p-d-flex p-jc-between p-m-2"},[_c('Button',{staticClass:"p-button-outlined p-mr-1",attrs:{"label":_vm.$t('button.ok'),"disabled":!column.createValid(_vm.newEntity),"data-cy":`${_vm.entity.type}_${_vm.options.title}_ok`},on:{"click":function($event){column.createNewEntity(column);
                _vm.cancelAddEntity(column.name);}}}),_c('Button',{staticClass:"p-button-secondary p-button-outlined",attrs:{"label":_vm.$t('button.cancel'),"data-cy":`${_vm.entity.type}_${_vm.options.title}_cancelCreate`},on:{"click":function($event){return _vm.cancelAddEntity(column.name)}}})],1)],1)])])])}),(_vm.currentEditingEntity.fields.length > 0)?_c('div',{staticClass:"p-px-1 p-py-0",staticStyle:{"flex-grow":"1"}},[_c('div',{staticClass:"column-list p-d-flex p-flex-column"},[_c('div',{staticClass:"p-d-flex p-jc-center",staticStyle:{"flex-grow":"1","overflow":"auto"}},[_c('TabView',{staticStyle:{"width":"100%"}},[_c('TabPanel',{staticStyle:{"width":"30vw"},attrs:{"header":_vm.$t('tabs.main')}},[_c('FieldsList',_vm._b({staticClass:"p-grid p-m-1",attrs:{"entityType":_vm.currentEditingEntity.entity.type},on:{"commit":_vm.commit}},'FieldsList',{ ..._vm.currentEditingEntity },false))],1),_c('TabPanel',{staticStyle:{"width":"30vw"},attrs:{"header":"JSON"}},[_c('JSONField',{attrs:{"entity":{ entity: _vm.currentEditingEntity.entity },"options":{
                title: 'entity',
                height: 'calc(85vh - 430px)',
                width: '100%',
                class: 'p-p-0',
                showLabel: false,
              }},on:{"commit":({ entity }) => _vm.commit(entity)}})],1)],1)],1),_c('div',{staticStyle:{"flex-grow":"1"}}),_c('div',{staticClass:"p-d-flex p-m-1 p-ai-center"},[_c('Button',{staticClass:"p-button-outlined p-mr-1",attrs:{"label":_vm.$t('button.save'),"data-cy":`${_vm.entity.type}_${_vm.options.title}_save`,"disabled":_vm.$v.currentEditingEntity.entity.$invalid},on:{"click":function($event){_vm.currentEditingEntity.onSave();
            _vm.cancelEditingEntity();}}}),_c('Button',{staticClass:"p-button-secondary p-button-outlined p-mr-1",attrs:{"label":_vm.$t('button.cancel'),"data-cy":`${_vm.entity.type}_${_vm.options.title}_cancel`},on:{"click":_vm.cancelEditingEntity}}),_c('ErrorMesseges',{staticClass:"p-lg-7 p-p-0",attrs:{"validator":_vm.$v.currentEditingEntity.entity,"attributes":_vm.fieldsLocales}})],1)])]):_vm._e(),_c('DeleteDialog',{attrs:{"show":_vm.displayDeleteDialog,"message":_vm.$t('message.deleteEntity'),"header":_vm.$t('message.deleteTitle')},on:{"update:show":function($event){_vm.displayDeleteDialog=$event},"onDelete":function($event){_vm.currentDeleteFunc(_vm.deleteElement);
      _vm.cancelEditingEntity();}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }