
import MapTalksMap from "./MapTalksMap.vue";
import TLayer from "./TLayer.vue";
import TPolygon from "./TPolygon.vue";
import TSector from "./TSector.vue";
import TMarker from "./TMarker.vue";
import TDrawTool from "./TDrawTool.vue";
import Vue from "vue";
import { uuid } from "@/utils";

type Mode = "single" | "multiple";
type Tool =
  | "Polygon"
  | "Line"
  | "Label"
  | "Sector"
  | "Rectangle"
  | "FreeHandLineString"
  | "FreeHandPolygon";

export default Vue.extend({
  name: "DrawOnMap",
  components: {
    TLayer,
    MapTalksMap,
    TPolygon,
    TMarker,
    // TLabel,
    TDrawTool,
    TSector,
  },
  props: {
    mode: {
      type: String as () => Mode,
      default: "single",
    },
    mapView: Object as () => MapView,
    editableGeometries: {
      type: Array as () => IGeometry[],
      default: () => [],
    },
    substrateGeometries: {
      type: Array as () => IGeometry[],
      default: () => [],
    },
    tools: {
      type: Array as () => Tool[],
      default: () => ["Polygon"],
    },
  },
  data() {
    return {
      displayDeleteDialog: false,
      currentMapView: this.mapView,
      drawTool: false,
      drawMode: null as string | null,
      currentEditing: "",
      labelEditing: "",
      counter: 0,
      copiedFeature: {} as any,
      maxChars: 25,
      localSubstrateGeometries: [] as IGeometry[],
      drawOptions: {
        symbol: {
          lineColor: "rgba(60, 114, 255, 1)",
          lineWidth: 6,
          polygonFill: "rgba(41, 66, 131, 0.2)",
          lineJoin: "round",
          shadowColor: "#3C72FF",
          shadowBlur: 10,
        },
        once: true,
      },
      drawButtons: {
        Polygon: {
          icon: "mdi-vector-polygon",
          drawMode: "Polygon",
          tooltip: this.$t("message.addPolygon"),
        },
        Sector: {
          icon: "mdi-angle-acute",
          drawMode: "Sector",
          tooltip: this.$t("message.addSector"),
        },
        Line: {
          icon: "mdi-vector-polyline",
          drawMode: "LineString",
          tooltip: this.$t("message.addPolyline"),
        },
        Label: {
          icon: "mdi-format-textbox",
          drawMode: "Point",
          tooltip: this.$t("message.addLabel"),
        },
        Rectangle: {
          icon: "mdi-rectangle-outline",
          drawMode: "Rectangle",
          tooltip: this.$t("message.addRectangle"),
        },
        FreeHandLineString: {
          icon: "mdi-draw",
          drawMode: "FreeHandLineString",
          tooltip: this.$t("message.drawLine"),
        },
        FreeHandPolygon: {
          icon: "mdi-vector-polyline-edit",
          drawMode: "FreeHandPolygon",
          tooltip: this.$t("message.drawPolygon"),
        },
      } as Record<Tool, any>,
    };
  },
  watch: {
    labelEditing(val: string) {
      if (val !== "") {
        const ref = this.$refs[val] as HTMLInputElement[];
        if (ref.length > 0 && ref[0].focus) {
          ref[0].focus();
        }
      }
    },
    currentMapView(val) {
      this.$emit("update:mapView", val);
    },
  },
  computed: {
    allFeatures(): IGeometry[] {
      return [...this.localSubstrateGeometries, ...this.editableGeometries];
    },
  },
  methods: {
    isArray: Array.isArray,
    setAsCurrent(feature: any, index: number) {
      this.currentEditing = !feature.freezed
        ? `${feature.id || index}_feature`
        : "";
      this.labelEditing = "";
    },
    updateLocation(position: any, geometry: any, index: number) {
      const geom = JSON.parse(JSON.stringify(geometry));
      geom["id"] = uuid();
      if (this.isArray(geometry.geometry.coordinates[0])) {
        geom.geometry.coordinates[0] = position;
      } else {
        geom.geometry.coordinates = position;
      }
      this.updateShape(index, geom);
    },
    endDraw(geometry: any) {
      let elements = [...this.editableGeometries];
      if (this.mode === "single") {
        elements = [geometry];
      } else {
        elements.push(geometry);
      }
      this.$emit("update:editableGeometries", elements);
      this.currentEditing = `${geometry.id}_feature`;
      this.drawMode = null;
    },
    updateShape(index: number, value: any) {
      const elements = [...this.editableGeometries];
      elements.splice(index - this.localSubstrateGeometries.length, 1, value);
      this.$emit("update:editableGeometries", elements);
    },
    deleteFeature(event: KeyboardEvent) {
      if (event.key === "Delete" && this.labelEditing === "") {
        event.stopPropagation();
        event.preventDefault();
        const elements = this.editableGeometries.filter((feature, index) => {
          return (
            this.currentEditing !==
            `${
              feature.id || index + this.localSubstrateGeometries.length
            }_feature`
          );
        });
        this.currentEditing = "";
        this.$emit("update:editableGeometries", elements);
      }
    },
    copyFeature(event: any) {
      if (this.labelEditing === "") {
        event.stopPropagation();
        event.preventDefault();
        const element = this.editableGeometries.find((feature, index) => {
          return (
            this.currentEditing ===
            `${
              feature.id || index + this.localSubstrateGeometries.length
            }_feature`
          );
        });
        if (element) {
          this.copiedFeature = element;
        }
      }
    },
    cutFeature(event: any) {
      this.copyFeature(event);
      const elements = this.editableGeometries.filter((feature, index) => {
        return (
          this.currentEditing !==
          `${
            feature.id || index + this.localSubstrateGeometries.length
          }_feature`
        );
      });
      this.currentEditing = "";
      this.$emit("update:editableGeometries", elements);
    },
    pasteFeature(event: any) {
      if (this.labelEditing === "") {
        event.stopPropagation();
        event.preventDefault();
        let element = {} as any;
        if (this.copiedFeature.id !== undefined) {
          element = { ...this.copiedFeature };
          element.id = uuid();
          const elements = [...this.editableGeometries, element];
          this.$emit("update:editableGeometries", elements);
        }
      }
    },
  },
  mounted() {
    this.substrateGeometries.forEach((el) => {
      el.freezed = true;
      this.localSubstrateGeometries.push(el);
    });
    document.addEventListener("keyup", this.deleteFeature);
    document.addEventListener("cut", this.cutFeature);
    document.addEventListener("copy", this.copyFeature);
    document.addEventListener("paste", this.pasteFeature);
  },
  destroyed() {
    document.removeEventListener("keyup", this.deleteFeature);
    document.removeEventListener("cut", this.cutFeature);
    document.removeEventListener("copy", this.copyFeature);
    document.removeEventListener("paste", this.pasteFeature);
  },
});
