var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"p-d-flex",staticStyle:{"padding":"0 !important","margin-top":"2.7rem"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(`${_vm.$t(`${_vm.entityType}.attrs.${_vm.options.title}`)}`),expression:"`${$t(`${entityType}.attrs.${options.title}`)}`",modifiers:{"top":true}}],staticClass:"p-button-outlined",class:{
      'p-button-danger': !(
        _vm.entity.outline['topViewSettings'] &&
        _vm.entity.outline &&
        _vm.entity.outline.border &&
        _vm.entity.outline.border.length > 3
      ),
    },attrs:{"icon":"mdi mdi-18px mdi-shape-polygon-plus","data-cy":`${_vm.entity.type}_${_vm.options.title}`},on:{"click":function($event){_vm.showMapDialog = true}}}),(_vm.showMapDialog)?_c('Dialog',{style:({
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
      maxHeight: '100vh',
      borderRadius: '0',
    }),attrs:{"visible":"","position":"center","showHeader":false,"dismissableMask":true,"closable":true,"modal":true},on:{"close":function($event){_vm.showMapDialog = false}}},[_c('div',{staticStyle:{"position":"relative"}},[_c('DrawOnMap',{attrs:{"mapView":_vm.currentMapView,"mode":"single","editableGeometries":_vm.features,"substrateGeometries":_vm.substrateFeatures,"tools":['Polygon']},on:{"update:mapView":function($event){_vm.currentMapView=$event},"update:map-view":function($event){_vm.currentMapView=$event},"update:editableGeometries":function($event){_vm.features=$event},"update:editable-geometries":function($event){_vm.features=$event}}})],1),_c('div',{staticStyle:{"position":"absolute","bottom":"2rem","left":"2rem"}},[_c('Button',{staticClass:"p-mr-2",attrs:{"label":_vm.$t('button.save'),"data-cy":`${_vm.entity.type}_${_vm.options.title}_save`,"disabled":!(_vm.features && _vm.features.length > 0)},on:{"click":_vm.saveAll}}),_c('Button',{staticClass:"p-mr-2 p-button-outlined",class:{ 'p-button-danger': !_vm.entity.outline['topViewSettings'] },attrs:{"label":_vm.$t('button.saveView'),"data-cy":`${_vm.entity.type}_${_vm.options.title}_saveView`},on:{"click":function($event){_vm.localTopViewSettings = _vm.currentMapView}}}),_c('Button',{staticClass:"p-button-secondary p-button-outlined",attrs:{"label":_vm.$t('button.cancel'),"data-cy":`${_vm.entity.type}_${_vm.options.title}_cancel`},on:{"click":function($event){_vm.showMapDialog = false}}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }