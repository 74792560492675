const mapObjects = new Map()

/**
 * This methods need when you try to use several maps on same page
 * @param {string} uuid
 * @param {any} map
 */
export const setWidgetMap = (uuid: string, map: any) => {
  mapObjects.set(uuid, map)
}
export const getWidgetMap = (uuid: string) => {
  return mapObjects.get(uuid)
}
