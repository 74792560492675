
import Vue from "vue";
import AutocompleteField from "../common/AutocompleteField.vue";
import { get, set } from "lodash-es";
import { debounce } from "@/utils";
import { mapStateTyped } from "@/store";

export default Vue.extend({
  name: "ReferenceField",
  components: {
    AutocompleteField,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      searchField: "",
      nameFetched: false,
      name: "",
    };
  },
  methods: {
    async fetchEntityTitle(id: string, type: string) {
      try {
        const { entity } = await this.api.getEntity({ id, type });
        this.$store.commit("setValue", [
          "existingReferenceIds",
          Array.from(new Set([...this.existingReferenceIds, entity.id])),
        ]);
        return entity[this.options.labelField];
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    localValue(val, prevVal) {
      if (val !== prevVal) {
        debounce(
          () => {
            this.fetchEntityTitle(val, this.options.entityType);
          },
          200,
          "ref"
        );
      }
    },
  },
  inject: ["isNewEntity"],
  computed: {
    ...mapStateTyped(["existingReferenceIds", "api", "spaParent"]),
    localValue: {
      set(val: string) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.$emit("commit", entity);
      },
      get(): string {
        return String(get(this.entity, this.options.title) || "");
      },
    },
    isDisabled(): boolean {
      if (this.spaParent.props?.disabled?.includes(this.options.title)) {
        return true;
      }
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      );
    },
  },
  async mounted() {
    if (this.localValue) {
      this.name = await this.fetchEntityTitle(
        this.localValue,
        this.options.entityType
      );
      this.nameFetched = true;
    }
  },
});
