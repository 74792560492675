var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Tree',{attrs:{"value":_vm.nodes,"expandedKeys":_vm.expandedKeys,"filter":true,"filterMode":"lenient","filterPlaceholder":_vm.$t('permissions.filterPlaceholder')},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('span',{staticClass:"node-label",staticStyle:{"cursor":"pointer"},domProps:{"textContent":_vm._s(slotProps.node.label)},on:{"click":function($event){return _vm.expandNode(slotProps.node.key)}}})]}},{key:"permissionTitle",fn:function(slotProps){return [_c('div',{staticClass:"p-d-flex p-ai-center permissions-actions"},[_c('span',{staticClass:"node-label p-mr-2",staticStyle:{"cursor":"pointer"},domProps:{"textContent":_vm._s(slotProps.node.permissionLabel)},on:{"click":function($event){return _vm.expandNode(slotProps.node.key)}}}),_c('span',{domProps:{"textContent":_vm._s(slotProps.node.data.title)}}),(slotProps.node.data.entityId)?_c('Button',{staticClass:"p-button-rounded p-button-text p-button-warning",attrs:{"type":"button","icon":"mdi mdi-18px mdi-pencil","label":_vm.$t('button.change')},on:{"click":function($event){_vm.currentPermissionPriority = slotProps.node.data;
            _vm.showPermissionPriorytyDialog = true;}}}):_vm._e(),(slotProps.node.data.entityId)?_c('Button',{staticClass:"p-button-rounded p-button-text p-button-danger",attrs:{"type":"button","icon":"mdi mdi-18px mdi-close","label":_vm.$t('button.delete')},on:{"click":function($event){_vm.deletePermissionId = slotProps.node.data.id;
            _vm.displayDeleteDialog = true;}}}):_vm._e()],1)]}},{key:"permissionLogic",fn:function(slotProps){return [_c('span',{staticClass:"node-label",style:({
          cursor: 'pointer',
          color:
            slotProps.node.data === 'NEGATIVE'
              ? 'var(--error-color)'
              : 'var(--success-color-dark)',
        }),domProps:{"textContent":_vm._s(slotProps.node.label)},on:{"click":function($event){return _vm.expandNode(slotProps.node.key)}}})]}},{key:"addPermission",fn:function(slotProps){return [_c('div',{staticClass:"p-d-flex p-ai-center permissions-actions"},[_c('span',{staticClass:"p-mr-1 node-label",domProps:{"textContent":_vm._s(slotProps.node.label)},on:{"click":function($event){return _vm.expandNode(slotProps.node.key)}}}),_c('Button',{staticClass:"p-button-rounded p-button-text",attrs:{"type":"button","icon":"mdi mdi-18px mdi-plus","label":_vm.$t('button.add')},on:{"click":function($event){_vm.currentPermissionPriority = {
              type: 'PermissionPriority',
              entityId: _vm.entity.id,
              entityType: _vm.entityType,
            };
            _vm.showPermissionPriorytyDialog = true;}}})],1)]}},{key:"changhePermissions",fn:function(slotProps){return [_c('div',{staticClass:"p-d-flex p-ai-center permissions-actionsn"},[_c('span',{staticClass:"p-mr-1 node-label",domProps:{"textContent":_vm._s(slotProps.node.label)},on:{"click":function($event){return _vm.expandNode(slotProps.node.key)}}}),_c('Button',{staticClass:"p-button-rounded p-button-text",attrs:{"type":"button","icon":"mdi mdi-18px mdi-pancil"}})],1)]}}])}),(_vm.showPermissionPriorytyDialog)?_c('CreateEditPermission',{attrs:{"onClose":_vm.onClose,"entityType":"PermissionPriority","entity":_vm.currentPermissionPriority}}):_vm._e(),_c('DeleteDialog',{attrs:{"show":_vm.displayDeleteDialog,"message":_vm.$t('message.deletePermission'),"header":_vm.$t('message.deleteTitle')},on:{"update:show":function($event){_vm.displayDeleteDialog=$event},"onDelete":_vm.deleteEntity}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }