
import Vue from "vue";
import { mapStateTyped } from "@/store";
import AutoComplete from "primevue/autocomplete";
const strictRegEx = /[^a-zA-Z0-9а-яА-Я-.,\s]/g;

export default Vue.extend({
  name: "AutocompleteField",
  components: {
    AutoComplete,
  },
  props: {
    value: String,
    editable: Boolean,
    entityType: String,
    searchField: String,
    label: String,
  },
  data() {
    return {
      localValue: this.value,
      suggestions: [] as any[],
      fetches: 0,
    };
  },
  computed: {
    ...mapStateTyped(["strictRegEx", "api"]),
  },
  watch: {
    localValue(val: string) {
      const clearedVal = val.replace(strictRegEx, "");
      if (this.localValue.length !== clearedVal.length) {
        this.localValue = clearedVal;
      }
    },
    value(val) {
      this.localValue = val;
    },
  },
  methods: {
    onSelect(event: any) {
      this.localValue = "";
      this.$emit("onSelect", event);
    },
    async fetchEntities(): Promise<void> {
      this.fetches += 1;
      if (this.fetches > 1) return;
      const payload: Record<string, object> = {
        limiter: {
          type: this.entityType,
          limit: 25,
        },
      };
      if (this.localValue !== "") {
        payload.filter = {
          q: [
            {
              key: this.searchField,
              value: this.localValue,
              operator: "~=",
              insensitify: true,
            },
          ],
        };
      }
      try {
        const { results } = await this.api.getEntitiesList(payload);
        this.suggestions = results;
        this.fetches = 0;
        return;
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("message.error"),
          detail: this.$t("autocomplete.errorMessage"),
          life: 5000,
        });
        console.error(error);
      }
    },
  },
});
