
import Vue from "vue";
import AimMarker from "../common/AimMarker.vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "LabelLocationField",
  components: {
    AimMarker,
  },
  props: {
    entity: Object,
    options: Object,
    context: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["maxChars"]),
  },
});
