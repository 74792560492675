
import Mixins from "./mixins";
import { uuid } from "./utils";
import { mapStateTyped } from "@/store";
import { repositoryGetter } from "@netvision/lib-api-repo";
// eslint-disable-next-line no-unused-vars 
import { GlobalEventBus, GlobalObserver } from "@netvision/lib-types-frontend";
import AdminDialog from "./components/AdminDialog.vue";

export default Mixins.extend({
  name: "App",
  components: {
    AdminDialog,
  },
  computed: {
    ...mapStateTyped(["api"]),
  },
  created() {
    const props: IWidgetProps<GlobalEventBus, GlobalObserver> | undefined = this.$parent?.props;
    if (!props) {
      throw new Error("Properties are not passed to the widget");
    }
    if (props.entity) {
      delete props.entity.$groupKey;
      delete props.entity.$index;
    } else {
      props.entity = {
        type: props.entityType,
      };
    }
    this.options = { ...props };
  },
  data() {
    return {
      loading: true,
      options: {} as IWidgetProps<GlobalEventBus, GlobalObserver>,
      entityTypeMetadata: {} as EntityMetadata,
      themeUnsubscribe: () => {},
    };
  },
  provide(): any {
    return {
      entityType: this.options.entityType,
      required: this.required,
      defaultMapLocation:
        this.$parent?.props.defaultMapLocation || "53.20017221, 50.12033860",
    };
  },
  async mounted() {
    this.entityTypeMetadata = await this.fetchEntityMetadata(
      this.options.entityType
    );
    this.loading = false;
  },
  beforeMount() {
    this.$store.commit("reset");
    this.$store.commit("setValue", ["spaParent", this.$parent]);
    this.$store.commit("setValue", [
      "api",
      repositoryGetter(this.$parent?.props?.lib),
    ]);
    this.$store.commit("setValue", ["widgetUUID", uuid()]);
    const themeEl = document.getElementById("theme") as HTMLLinkElement & {
      setTheme: () => void;
    };
    this.$store.commit("setValue", [
      "isDarkTheme",
      themeEl.getAttribute("theme-name") === "dark",
    ]);
    const themeSubscribe = (func: (newValue: boolean) => void) => {
      const listener = (e: any) => func(e.detail === "dark");
      themeEl.addEventListener("update", listener);
      return () => themeEl.removeEventListener("update", listener);
    };
    this.themeUnsubscribe = themeSubscribe((newValue) => {
      this.$store.commit("setValue", ["isDarkTheme", newValue]);
    });
  },
  beforeDestroy() {
    this.themeUnsubscribe();
  },
});
