
import Vue from "vue";
import TableStreams from "./widgets/TableStreams.vue";
import ErrorPlaceholder from "./widgets/ErrorPlaceholder.vue";
import { name } from "../../../package.json";
import PasswordWidget from "./widgets/PasswordWidget.vue";

export default Vue.extend({
  name: "WidgetMountField",
  props: {
    entity: Object,
    options: Object,
  },
  computed: {
    mountComponent(): any {
      switch (this.options.tabEntityKey) {
        case "streamsTable":
          return TableStreams;
        case "password-widget":
          return PasswordWidget;
        default:
          return ErrorPlaceholder;
      }
    },
  },
  data() {
    return {
      isReady: false,
      widgetName: name,
      unmount: () => {},
    };
  },
  beforeDestroy() {
    this.unmount();
  },
});
