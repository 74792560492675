
import Vue from "vue";
import { get, set } from "lodash-es";

export default Vue.extend({
  name: "SetDefault",
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {};
  },
  computed: {
    localValue: {
      set(val: any) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.$emit("commit", entity);
      },
      get(): any {
        return get(this.entity, this.options.title);
      },
    },
  },
  mounted() {
    if (this.localValue === undefined) {
      this.localValue = this.options["default"];
    }
  },
});
