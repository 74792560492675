
import Vue from "vue";
import { mapStateTyped } from "@/store";
import { get, set } from "lodash-es";
import Textarea from "primevue/textarea";

export default Vue.extend({
  name: "TextField",
  components: {
    Textarea,
  },
  props: {
    entity: Object,
    options: Object,
  },
  inject: ["isNewEntity"],
  computed: {
    ...mapStateTyped(["strictRegEx"]),
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      );
    },
    localValue: {
      set(val: string) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val.replace(this.strictRegEx, ""));
        this.$emit("commit", entity);
      },
      get(): Object {
        return get(this.entity, this.options.title) || "";
      },
    },
  },
  data() {
    return {};
  },
});
