
import Vue from "vue";
import {
  coordinatesToPosition,
  locationToCoordinates,
  coordinatesToLocation,
} from "@/utils";
import DrawOnMap from "../map/DrawOnMap.vue";
import CameraCard from "../common/CameraCard.vue";
import Moveable from "vue-moveable";

export default Vue.extend({
  name: "CameraDirectionFielfd",
  props: {
    entity: Object as () => ComplexObject,
    options: Object,
    context: Object,
  },
  components: {
    DrawOnMap,
    CameraCard,
    Moveable,
  },
  data() {
    return {
      moveable: {
        draggable: true,
      },
      displayDeleteDialog: false,
      showMapDialog: false,
      notSavedValue: undefined as ISector[] | undefined,
      drawPolygonTool: false,
    };
  },
  watch: {
    showMapDialog(val: boolean) {
      if (!val) {
        this.notSavedValue = undefined;
      }
    },
  },
  inject: ["defaultMapLocation"],
  computed: {
    features: {
      set(sectors: ISector[]) {
        this.notSavedValue = sectors;
      },
      get(): ISector[] {
        return this.notSavedValue === undefined
          ? this.localValue
            ? [this.localValue]
            : []
          : this.notSavedValue;
      },
    },
    substrateFeatures() {
      let features = [] as any;
      if (this.context && Array.isArray(this.context.features)) {
        features = [...this.context.features];
      }
      if (this.context && this.context.outline && this.context.outline.border) {
        features.push({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [this.context["outline"].border],
          },
        });
      }
      if (this.entity.location && this.entity.title) {
        features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: this.notSavedValue
              ? this.notSavedValue[0]?.center
              : locationToCoordinates(this.entity.location),
          },
        });
      }
      return features;
    },
    localValue: {
      set(val: ISector): void {
        const { center, minimumClock, maximumClock } = val;
        const entity = { ...this.entity };
        entity.location = coordinatesToLocation(center);
        entity[this.options.title] = { minimumClock, maximumClock };
        this.$emit("commit", entity);
      },
      get(): ISector {
        return this.entity && this.entity[this.options.title]
          ? {
              type: "Sector",
              id: this.entity.id,
              center: this.locationToCoordinates(this.entity.location),
              ...this.entity[this.options.title],
            }
          : null;
      },
    },
    localTopViewSettings() {
      let initPosition = {} as MapView;
      if (this.context?.outline.topViewSettings) {
        const { heading, center, zoom, position, pitch, bearing } =
          this.context?.outline.topViewSettings;
        initPosition = {
          center: center ?? [position?.lng, position?.lat],
          zoom: zoom ?? this.context.expandingZoom,
          bearing: bearing ?? heading,
          pitch,
        };
      } else {
        initPosition = {
          center: (this.context?.location &&
            this.locationToCoordinates(this.context.location as string)) ||
            (this.entity?.location &&
              this.locationToCoordinates(this.entity.location as string)) ||
            this.locationToCoordinates(
              //@ts-ignore
              this.defaultMapLocation as string
            ) || [53.18575535, 50.08787507],
          zoom: 20,
          pitch: 0,
          bearing: 0,
        };
      }
      //@ts-ignore
      return initPosition;
    },
  },
  methods: {
    saveAll() {
      if (this.notSavedValue) {
        this.localValue = this.notSavedValue[0];
      }
      this.showMapDialog = false;
    },
    handleDrag({ target, transform }: any) {
      target.style.transform = transform;
    },
    coordinatesToLocation,
    coordinatesToPosition,
    locationToCoordinates,
  },
  mounted() {},
});
