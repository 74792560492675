
import Vue from "vue";
import MarkerCircle from "../common/MarkerCircle.vue";
import MapTalksMap from "../map/MapTalksMap.vue";
import TMarker from "../map/TMarker.vue";
import TLayer from "../map/TLayer.vue";
import { get, set } from "lodash-es";
import { coordinatesToLocation, locationToCoordinates } from "@/utils";

const coordsRegEx = /^-?\d{1,3}\.\d{3,}, -?\d{1,3}\.\d{3,}$/;
export default Vue.extend({
  name: "CoordinatesField",
  components: {
    MapTalksMap,
    MarkerCircle,
    TMarker,
    TLayer,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      showMapDialog: false,
      location: "" as string | number[],
      localValueInputed: "" as string | number[],
    };
  },
  watch: {
    localValueInputed(val: string) {
      const clearedVal = val.replace(/[^0-9.,-\s]/g, "");
      if (val.length === clearedVal.length && this.validCoordinates) {
        this.localValue = val;
      } else {
        this.localValueInputed = clearedVal;
      }
    },
  },
  inject: ["defaultMapLocation", "isNewEntity"],
  computed: {
    validCoordinates(): boolean {
      return coordsRegEx.test(this.localValueInputed as string);
    },
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      );
    },
    mapView(): any {
      return {
        center: this.locationToCoordinates(this.localValue as string),
        zoom: 14,
        pitch: 45,
        bearing: 0,
      };
    },
    localValue: {
      set(val: string) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.location = this.localValueInputed = val;
        this.$emit("commit", entity);
      },
      get(): string {
        //@ts-ignore
        return get(this.entity, this.options.title) || this.defaultMapLocation;
      },
    },
  },
  methods: {
    viewChange({ x, y }: { x: number; y: number }) {
      this.location = this.coordinatesToLocation([x, y]);
    },
    coordinatesToLocation,
    locationToCoordinates,
  },
  mounted() {
    this.location = this.localValueInputed = this.localValue;
  },
});
