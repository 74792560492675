
import Vue from "vue";
import { mapStateTyped } from "@/store";
import { debounce } from "@/utils";
import { isEqual, get, set } from "lodash-es";
import AutocompleteField from "../common/AutocompleteField.vue";

export default Vue.extend({
  name: "MultiReferenceField",
  components: {
    AutocompleteField,
  },
  props: {
    entity: Object,
    entityType: String,
    options: Object as () => {
      title: string;
      type: "multiReference";
      entityType: string;
      labelField: string;
    },
  },
  data() {
    return {
      findEntity: "",
      entityIdForDelete: "",
      displayDeleteDialog: false,
      localLoading: false,
      referenceLabels: {} as Record<string, string>,
    };
  },
  watch: {
    localValue(val, prevVal) {
      if (!isEqual(val, prevVal) && val.length > 0)
        debounce(this.fetchReferencedEntities, 200, "multi");
    },
  },
  computed: {
    ...mapStateTyped(["loading", "existingReferenceIds", "api"]),
    localValue: {
      set(val: string[]) {
        const entity = { ...this.entity };
        set(entity, this.options.title, val);
        this.$emit("commit", entity);
      },
      get(): string[] {
        return get(this.entity, this.options.title) || [];
      },
    },
  },
  methods: {
    addEntityInValue(event: any) {
      if (event) {
        this.findEntity = "";
        this.referenceLabels[event.value.id] =
          event.value[this.options.labelField];
        this.localValue = Array.from(
          new Set([event.value.id, ...this.localValue])
        );
      }
    },
    deleteEntityFromValue() {
      if (![undefined, ""].includes(this.entityIdForDelete)) {
        this.localValue = this.localValue.filter(
          (e) => e !== this.entityIdForDelete
        );
      }
    },
    async fetchReferencedEntities() {
      this.$store.commit("setValue", ["loading", true]);
      this.localLoading = true;
      try {
        const { results: referencedEntities } = await this.api.batchQuery({
          limiter: {
            limit: this.localValue.length,
          },
          filter: {
            entities: this.entity[this.options.title].map((id: string) => ({
              id,
              type: this.options.entityType,
            })),
          },
        });
        const ids = [] as string[];
        referencedEntities.forEach(
          ({ id, [this.options.labelField]: label }: Record<string, any>) => {
            if (id) {
              ids.push(id);
              this.referenceLabels[id] = label;
            }
          }
        );
        this.$store.commit("setValue", [
          "existingReferenceIds",
          Array.from(new Set([...this.existingReferenceIds, ...ids])),
        ]);
      } catch (error) {
        console.error(error);
      }
      this.$store.commit("setValue", ["loading", false]);
      this.localLoading = false;
    },
  },
  mounted() {
    this.localValue.length > 0 && this.fetchReferencedEntities();
  },
});
