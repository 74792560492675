
import Vue from "vue";
export default Vue.extend({
  name: "DeleteDialog",
  props: {
    show: Boolean,
    message: String,
    header: String,
  },
  data() {
    return {
      displayDeleteDialog: this.show,
    };
  },
  watch: {
    show(val) {
      this.displayDeleteDialog = val;
    },
    displayDeleteDialog() {
      this.$emit("update:show", this.displayDeleteDialog);
    },
  },
});
